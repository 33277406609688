import {Component, Input, OnChanges, Injector, SimpleChanges, OnInit, ViewChild, AfterViewChecked} from '@angular/core';
import {GlobalModalService, GlobalModalParent, GlobalModalChild} from 'src/app/kanso-common/core/service/globalmodal.service';
import {ControlContainer, FormControl, NgForm} from '@angular/forms';
import * as moment from 'moment';
import * as lodash from 'lodash';

import {AccountingService} from '../../../accounting/service/accounting.service';
import {VendorService} from 'src/app/custom/vendor/vendor.service';
import {CoreService} from 'src/app/core/service/core.service';
import {CustomAttributesValue, ICustomAttributesValue, CustomAttribute, CustomAttributes, Note} from 'src/app/core/service/core-models';
import {CustomAttributesComponent} from '../../../../kanso-common/core/components/custom-attributes/custom-attributes.component';
import {
  Authority,
  ILandlordAddress,
  Program,
  Project,
  IRentCalcDetails,
  Unit,
  UnitModalData,
  UnitUiSections,
  Vendor,
  VendorTypes,
  UnitRentCalc,
  IVendorAch,
  VendorNote,
} from '../../../housing-core/services/housing-models';
import {UnitSetupMonthlyChargeComponent} from './unit-setup-monthly-charge/unit-setup-monthly-charge.component';
import _ from 'lodash';
import {Observable, combineLatest} from 'rxjs';
import {debounceTime, map, startWith, switchMap} from 'rxjs/operators';
import {vendorNode} from 'src/app/custom/vendor/vendor-model';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {UtilityAllowance} from 'src/app/kanso-common/core/service/core-models';

export interface IaugmentedVendor extends Vendor {
  name: string;
}
export class augmentedVendor implements IaugmentedVendor {
  /**
   *
   */
  constructor(vendor: Vendor) {
    this.name = vendor.companyName ? vendor.companyName : `${vendor.firstName} ${vendor.lastName}`;
    this.id = vendor.id;
    this.vendorType = vendor.vendorType;
  }
  vendorAch: IVendorAch;
  name: string;
  id: string;
  vendorType: string;
  firstName: string;
  lastName: string;
  payTo: string;
  companyName: string;
  phoneNumber: string;
  emailAddress: string;
  addresses: ILandlordAddress[];
  taxId: string;
  externalReferenceId: string;
  paymentPreference: string;
  notes: VendorNote[];
  customAttributes?: any;
  attributeValues?: any;
  _id?: string;
  createdOn?: Date;
  createdBy?: string;
  modifiedOn?: Date;
  modifiedBy?: string;
  archivedOn?: Date;
  archivedBy?: string;
  accountNumber: string;
  routingNumber: string;
  phaCode?: string;
}
@Component({
  selector: 'unit-setup',
  templateUrl: './unit-setup.component.html',
  styleUrls: ['./unit-setup.component.scss'],
  providers: [{provide: ControlContainer, useExisting: NgForm}],
})
export class UnitSetupComponent implements GlobalModalChild<UnitModalData>, OnInit, AfterViewChecked {
  globalModalParent: GlobalModalParent;
  @ViewChild('unitForm') unitForm: NgForm;
  @Input() editMode: boolean;

  _unit: Unit;
  @Input() set unit(setUnit: Unit) {
    this._unit = setUnit;
    this.editUnit = _.cloneDeep(setUnit);
    this.customAttributes = this.editUnit.customAttributes;
  }
  get unit() {
    return this._unit;
  }
  @Input() unitId; //From Unit Details
  @Input() openPanel: UnitUiSections;
  @Input() housingAuthority: Authority;
  @Input() programs: Program[];
  @Input() projects: Project[];

  _defaultRentCalc: IRentCalcDetails;
  @Input() set defaultRentCalc(setRentCalc: IRentCalcDetails) {
    this._defaultRentCalc = setRentCalc;
    if (!this.unit.rentCalc) {
      this.editUnit.rentCalc = this.defaultRentCalc;
    }
  }
  get defaultRentCalc() {
    return this._defaultRentCalc;
  }

  // eslint-disable-next-line
  @Input() updateFunction: Function;
  // eslint-disable-next-line
  @Input() cancelFunction: Function;

  editUnit: Unit;
  customAttributes: any[];

  landlordCtrl = new FormControl('');
  filteredLandlordVendors$: Observable<vendorNode[]>;
  utilityVendorCtrl = new FormControl('');
  filteredUtilityVendors$: Observable<vendorNode[]>;
  utilityAllowanceCtrl = new FormControl('');
  filteredUtilityAllowances$: Observable<UtilityAllowance[]>;
  //???
  fromUnitDetails: boolean;
  isBusy = false;
  overrideProgramSettings: boolean;
  tenantCheck: boolean;
  landlordVendors: augmentedVendor[];
  utilityVendors: augmentedVendor[];
  program: Program;
  project: Project;
  programList;
  projectList;
  allProjectList;
  unitFeesDirty = false;
  unitOverrideDelete = false;
  generalInfoPanelOpenState = true;
  customAttributesInfoPanelOpenState = false;
  monthlyChargeInfoPanelOpenState = false;
  accountingInfoPanelOpenState = false;
  attributeCount = 1;
  convertedProjectList;

  loggedInUser;
  firstInitilize = false;

  constructor(
    public accountingService: AccountingService,
    public vendorService: VendorService,
    private customAttributesComponent: CustomAttributesComponent,
    public coreService: CoreService,
    public globalModalService: GlobalModalService,
    protected injector: Injector
  ) {}

  ngOnInit(): void {
    switch (this.openPanel) {
      case UnitUiSections.CustomAttributes:
        this.generalInfoPanelOpenState = false;
        this.customAttributesInfoPanelOpenState = true;
        break;
      default:
        break;
    }

    //fetch existing landlord if set
    if (!_.isNil(this.editUnit.landlordId)) {
      this.vendorService.getVendorV2(this.editUnit.landlordId).subscribe(landlord => {
        if (landlord) {
          this.landlordCtrl.setValue(landlord);
        }
      });
    }

    //fetch existing vendor utility if set
    if (!_.isNil(this.editUnit.utilityVendorId)) {
      this.vendorService.getVendorV2(this.editUnit.utilityVendorId).subscribe(utilityVendor => {
        if (utilityVendor) {
          this.utilityVendorCtrl.setValue(utilityVendor);
        }
      });
    }

    //create landlord stream
    this.filteredLandlordVendors$ = this.landlordCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((value: string) => this.vendorService.getVendorsV2(50, '', 'forward', value, VendorTypes.Landlord))
    );

    //create utilty vendor stream
    this.filteredUtilityVendors$ = this.utilityVendorCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((value: string) => this.vendorService.getVendorsV2(50, '', 'forward', value, VendorTypes.Utility))
    );

    //create utilty allowance stream
    this.filteredUtilityAllowances$ = this.utilityAllowanceCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((value: string) =>
        this.vendorService.getAllUtilityAllowances(this.accountingService.envDat.siteId, this.accountingService.envDat.customerId, value)
      )
    );

    if (this.programs) {
      this.programList = this.programs;
      this.allProjectList = this.projects;
      this.program = this.programList.filter(program => program._id == this.editUnit.program)[0];
      this.isPublicHousing = this.program ? this.program.type == 'Public Housing' : false;
      this.convertedProjectList = this.allProjectList.filter(apl => apl.program == this.editUnit.convertedFromProgramId);
    }

    if (this.program) {
      if (this.program.rentCalc.isUtilityAllowanceCalculations) {
        if (!_.isNil(this.editUnit.utilityAllowanceId)) {
          this.vendorService.getUtilityAllowance(this.editUnit.utilityAllowanceId).subscribe(utilityAllowance => {
            if (utilityAllowance) {
              this.utilityAllowanceCtrl.setValue(utilityAllowance);
            }
          });
        }
      }
    }
    this.firstInitilize = this.editMode;
  }

  ngAfterViewChecked(): void {
    if (this.firstInitilize) {
      const formObject = this.unitForm.form;
      if (Object.keys(formObject.controls).length !== 0) {
        if (!formObject.valid) {
          let errorFields = '';
          for (const key in formObject.controls) {
            // eslint-disable-next-line
            if (formObject.controls.hasOwnProperty(key)) {
              if (!formObject.controls[key].valid && formObject.controls[key].enabled) {
                const initialFieldInfo = `${key}:`;
                if (formObject.controls[key].hasError('pattern')) {
                  errorFields += !errorFields.includes(initialFieldInfo)
                    ? `\n${key}: Invalid format for this field.`
                    : `\n${' '.repeat(initialFieldInfo.length)} Invalid format for this field.`;
                }

                if (formObject.controls[key].hasError('required')) {
                  errorFields += !errorFields.includes(initialFieldInfo)
                    ? `\n${key}: This field is required.`
                    : `\n${' '.repeat(initialFieldInfo.length)} This field is required.`;
                }

                if (formObject.controls[key].hasError('minlength')) {
                  errorFields += !errorFields.includes(initialFieldInfo)
                    ? `\n${key}: The minimum length is ${formObject.controls[key].errors.minlength?.requiredLength} characters.`
                    : `\n${' '.repeat(initialFieldInfo.length)} The minimum length is ${
                        formObject.controls[key].errors.minlength?.requiredLength
                      } characters.`;
                }

                if (formObject.controls[key].hasError('maxlength')) {
                  errorFields += !errorFields.includes(initialFieldInfo)
                    ? `\n${key}: The maximum length is ${formObject.controls[key].errors.maxlength?.requiredLength} characters.`
                    : `\n${' '.repeat(initialFieldInfo.length)} The maximum length is ${
                        formObject.controls[key].errors.maxlength?.requiredLength
                      } characters.`;
                }
              }
            }
          }

          this.coreService.displayError(
            'There is an error with your data, please adjust these fields to update the unit:',
            () => {
              this.unitForm.form.markAllAsTouched();
            },
            'Warning',
            errorFields
          );
        }
        this.firstInitilize = false;
      }
    }
  }

  vendorSelectDisplay(option: vendorNode): string {
    return option?.name || '';
  }

  utilitySelectDisplay(option: UtilityAllowance): string {
    return option?.name || '';
  }

  onLandlordSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedVendor: vendorNode = event.option.value;

    // Update your model property and mark the form as dirty
    this.editUnit.landlordId = selectedVendor.id;
    this.landlordCtrl.markAsDirty();
  }

  clearLandlordSelection(): void {
    // Clear the selection and update your model property
    this.landlordCtrl.setValue(null);
    this.editUnit.landlordId = null;
    this.landlordCtrl.markAsDirty();
  }

  onUtilityVendorSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedVendor: vendorNode = event.option.value;
    this.editUnit.utilityVendorId = selectedVendor.id;
    this.utilityVendorCtrl.markAsDirty();
  }

  onUtilityAllowanceSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedUtilityAllowance: UtilityAllowance = event.option.value;
    if (selectedUtilityAllowance.id) {
      this.editUnit.utilityAllowanceId = selectedUtilityAllowance.id;
      this.utilityVendorCtrl.markAsDirty();
    }
  }

  clearUtilitySelection(): void {
    // Clear the selection and update your model property
    this.utilityVendorCtrl.setValue(null);
    this.editUnit.utilityVendorId = null;
    this.utilityVendorCtrl.markAsDirty();
  }

  clearUtilityAllowanceSelection(): void {
    // Clear the selection and update your model property
    this.utilityAllowanceCtrl.setValue(null);
    this.editUnit.utilityAllowanceId = '';
    this.utilityAllowanceCtrl.markAsDirty();
  }

  isPublicHousing = false;
  attributeChangeHandler(currentCustomAttributes: CustomAttributes[]) {
    this.attributeCount = currentCustomAttributes.length ?? 0;
  }
  toggleOverride(overrideRentCalc) {
    if (overrideRentCalc == false) {
      // reset to default rentcalc
      this.editUnit.rentCalc = this.defaultRentCalc;
    }
    this.unitOverrideDelete = this.unit.overrideRentCalc == true && overrideRentCalc == false;
  }

  onProgramChange() {
    this.editUnit.rentCalc = null;
    this.monthlyChargeInfoPanelOpenState = false;
    if (this.projectList) {
      this.editUnit.project = null;
    }
    this.program = this.programList.filter(program => program._id == this.editUnit.program)[0];
    this.projectList = this.allProjectList.filter(apl => apl.program == this.editUnit.program);
  }

  onProjectChange() {
    this.project = this.projectList.filter(pl => pl._id == this.editUnit.project)[0];
    if (this.project || this.program) {
      this.editUnit = {...this.editUnit, rentCalc: this.project.rentCalc ? this.project.rentCalc : this.program.rentCalc};
    } else {
      this.editUnit = {...this.editUnit, rentCalc: null};
    }
  }

  onConvertedChanged() {
    this.editUnit.convertedFromProjectId = null;
    this.convertedProjectList = this.allProjectList.filter(apl => apl.program == this.editUnit.convertedFromProgramId);
  }

  onLandlordVendorChanged() {
    this.editUnit.isNonInventoryUnit = this.editUnit.landlordId !== this.housingAuthority.landlordId;
  }

  compareValues(o1, o2) {
    //compare the Id directly
    if (o1 == o2) {
      return true;
    } else {
      return false;
    }
  }

  newMonthlyChargesDialog(chargeType: string) {
    this.globalModalService.openModal(UnitSetupMonthlyChargeComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      chargeType: chargeType,
      value: 0,
      bedroomSize: this.editUnit.numberOfBedrooms,
      updateFunction: this.addMonthlyCharge,
    });
  }

  openMonthlyChargesDialog(chargeType: string, index) {
    const rentcalcOption = this.editUnit.rentCalc[chargeType];
    const value = rentcalcOption[index].value[this.editUnit.numberOfBedrooms];
    const effectiveDate = rentcalcOption[index].effectiveDate;
    this.globalModalService.openModal(UnitSetupMonthlyChargeComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      chargeType: chargeType,
      effectiveDate: effectiveDate,
      value: value / 100,
      bedroomSize: this.editUnit.numberOfBedrooms,
      index: index,
      updateFunction: this.updateMonthlyCharge,
    });
  }

  addMonthlyCharge = (value, effectiveDate, chargeType, bedroomSize) => {
    const newMonthlyChargeOption = {
      createdBy: this.loggedInUser,
      createdOn: new Date(),
      effectiveDate: moment(effectiveDate).format('YYYY/MM/DD'),
      modifiedBy: null,
      modifiedOn: new Date(),
      value: [],
    };
    newMonthlyChargeOption.value[bedroomSize] = value * 100;
    this.editUnit.rentCalc[chargeType].push(newMonthlyChargeOption);
    this.unitFeesDirty = true;
  };

  updateMonthlyCharge = (value, effectiveDate, chargeType, bedroomSize, index) => {
    this.editUnit.rentCalc[chargeType][index].effectiveDate = moment(effectiveDate).format('YYYY/MM/DD');
    this.editUnit.rentCalc[chargeType][index].modifiedOn = new Date();
    this.editUnit.rentCalc[chargeType][index].modifiedBy = this.loggedInUser;
    this.editUnit.rentCalc[chargeType][index].value[bedroomSize] = value * 100;
    this.unitFeesDirty = true;
  };

  deleteMonthlyCharge(chargeType: string, index) {
    this.editUnit.rentCalc[chargeType].splice(index, 1);
    this.unitFeesDirty = true;
  }

  unitTenantCheck() {
    if (!this.editUnit.statusHistory || this.editUnit.statusHistory.length == 0) {
      this.tenantCheck = false;
    } else {
      const statusHistory = lodash
        .chain(this.editUnit.statusHistory)
        .orderBy(['effectiveDate', 'createdOn'], ['desc', 'desc'])
        .head()
        .value();
      this.tenantCheck = ['Occupied', 'Assigned', 'Conveyance Eligible', 'Conveyed'].includes(statusHistory.unitStatus);
    }
  }

  getCurrentUsersLogInCookie() {
    const cookies = document.cookie.split(';');
    let usersAuthCookie;
    for (const cookie of cookies) {
      if (cookie.includes('LastAuthUser')) {
        //found the cookie of the user
        usersAuthCookie = cookie;
      }
    }
    const arrayOfCookieStrings = usersAuthCookie.split('=');
    this.loggedInUser = arrayOfCookieStrings[1];
  }

  numericOnly(event): boolean {
    const patt = /^([0-9])$/;
    const result = patt.test(event.key);
    return result;
  }

  save() {
    //get attribute values
    if (this.customAttributes.length > 0) {
      if (this.customAttributes[0].__typename != 'UnitAttributeValueViewModel') {
        const attributeValues: CustomAttributesValue[] = [];
        this.customAttributes.forEach((a): void => {
          const value = new CustomAttributesValue(a);
          value.entityInstanceId = this.unit.id;
          try {
            attributeValues.push(value as CustomAttributesValue);
          } catch (error) {
            console.log(error);
          }
        });
        this.editUnit.customAttributes = attributeValues;
      }
      const zipCode = this.editUnit.postalCode;
      const zipCode4 = this.editUnit.postalCodePlus4;
      if (!/^[\d]{5}$/.test(zipCode)) {
        alert('Invalid zip code');
        return;
      }
      if (!/^[\d]{4}$|/.test(zipCode4)) {
        alert('Invalid zip code extension');
        return;
      }
    }
    this.updateFunction(this.editUnit);
    this.globalModalParent.closePopup();
    return;
  }

  cancel() {
    this.globalModalParent.closePopup();
    this.cancelFunction();
  }
}
