<mat-card class="knso-card program-setup-container">
    <div *ngIf="loading" class="layout-row spinnerPadding">
        <mat-spinner diameter="25" > </mat-spinner> 
        <div class="leftPadding">Loading Program...</div>
    </div>
    <mat-card-header *ngIf= "!loading">
        <div>
            <mat-toolbar class="layout-row">
                <div *ngIf="selectedProgram.name == null">
                    <span>New Program</span>
                </div>
                <div *ngIf="selectedProgram.name !== null">
                    <span class="programText">{{selectedProgram.name}}</span>
                </div>
                <button mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
            </mat-toolbar>
        </div>
    </mat-card-header>
    <form #editProgramForm="ngForm" class="modal-content">
        <mat-card-content *ngIf= "!loading">
            <div class="mat-card-content-content">
                <div class="section-header chevronAlign">
                    <a class="account__details-collapse">
                        <mat-icon class="collapse-icon" 
                            [ngClass]="{'collapse-icon--open' : generalInfoPanelOpenState}"
                            (click)="generalInfoPanelOpenState = !generalInfoPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="headerPadding">General Information</div>
                </div>
                <mat-expansion-panel [expanded]="generalInfoPanelOpenState" class="hidePanel">
                    <div class="section-body layout-row layout-align-start-center">
                        <mat-form-field class="programName" appearance="outline">
                            <mat-label>Program Name</mat-label>
                            <input 
                                matInput
                                ngModel
                                [(ngModel)]="selectedProgram.name"  
                                id="programNameInput" 
                                aria-label="Program name"
                                #programNameInput="ngModel"
                                name="programNameInput"
                                required>
                                <mat-error *ngIf="programNameInput.hasError('required')">
                                    <div ng-message="required">Program name is required</div>
                                </mat-error>
                        </mat-form-field>
                    
                        <mat-form-field class="programCode" appearance="outline">
                            <mat-label>Program Code</mat-label>
                            <input 
                                matInput
                                ngModel 
                                [(ngModel)]="selectedProgram.programCode"
                                id="programCodeInput"
                                aria-label="Program code"
                                #programCodeInput="ngModel"
                                name="programCodeInput">
                        </mat-form-field>
                        <mat-form-field class="programType" appearance="outline">
                            <mat-label>Program Type</mat-label>
                            <mat-select
                                name="programType"
                                id="programType"
                                ngModel
                                [disabled]="editMode"
                                [(ngModel)]="selectedProgram.programType"
                                #programType="ngModel"
                                required
                                (selectionChange)="programTypeSelected($event)">
                                <mat-option *ngFor="let type of programTypes" [value]="type">
                                    {{type}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="section-body layout-row layout-align-start-center">
                        <mat-form-field class="phaCode" appearance="outline">
                            <mat-label>PHA Code</mat-label>
                            <input 
                                matInput
                                ngModel 
                                [(ngModel)]="selectedProgram.phaCode"
                                aria-label="PHA Code"
                                maxlength="5"
                                id="programPhaCodeInput"
                                #programPhaCodeInput="ngModel"
                                name="programPhaCodeInput">
                        </mat-form-field>

                        <mat-form-field class="aelValue" appearance="outline">
                            <mat-label>46% of AEL Value</mat-label>
                            <input 
                                matInput 
                                ngModel
                                [(ngModel)]="selectedProgram.aelValue"
                                type="number"
                                monetary-input
                                aria-label="AEL Value"
                                id="programAelInput"
                                #programAelInput="ngModel"
                                name="programAelInput">
                        </mat-form-field>
                    </div>

                    <div class="section-body layout-row layout-align-start-center">
                        <div class="requires58PicForm wordPadding58" appearance="outline">Does this program require<br/>50058 Form/PIC Reporting?</div>
                        <mat-error *ngIf="programRequires58.hasError('required')">
                            <div ng-message="required">This field is required</div>
                        </mat-error>
                        <mat-radio-group ngModel
                            [(ngModel)]="selectedProgram.requires58PicForm"
                            aria-label="Does this program require a 50058 Form/Pic Reporting?"
                            class="layout-row"
                            id="programRequires58"
                            #programRequires58="ngModel"
                            name="programRequires58"
                            required>
                            <mat-radio-button [value]="true" class="md-primary leftPadding" id="pic58Required">Yes</mat-radio-button>
                            <mat-radio-button [value]="false" class="md-primary leftPadding" id="pic58NotRequired">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </mat-expansion-panel>

                <div *ngIf="selectedProgram.programType !== 'Misc'" class="section-header chevronAlign">
                    <a class="account__details-collapse">
                        <mat-icon class="collapse-icon" 
                            [ngClass]="{'collapse-icon--open' : certificationInfoPanelOpenState}"
                            (click)="certificationInfoPanelOpenState =!certificationInfoPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="headerPadding">Certification Information</div>
                </div>
                <mat-expansion-panel [expanded]="certificationInfoPanelOpenState" class="hidePanel">
                    <div class="leftPadding section-body" *ngIf="selectedProgram.programType !== 'Misc'">
                        <mat-checkbox 
                            ngModel 
                            [(ngModel)]="selectedProgram.isRecertificationRequired"
                            id="isRecertRequired"
                            #isRecertRequired="ngModel"
                            name="isRecertRequired"
                            required>
                        </mat-checkbox>
                        <mat-label class="wordPadding">Certification Required</mat-label>
                        <mat-error *ngIf="isRecertRequired.hasError('required')">
                            <div ng-message="required">This field is required</div>
                        </mat-error>
                    </div>
                    <div class="section-body layout-row layout-align-start-center"
                        *ngIf="selectedProgram.isRecertificationRequired && selectedProgram.programType !== 'Misc'">
                        <mat-form-field class="recertPeriod" appearance="outline">
                            <mat-label>Certification Period</mat-label>
                            <input 
                                matInput 
                                ngModel
                                [(ngModel)]="selectedProgram.recertificationPeriod" 
                                id="programCertInput"
                                #programCertInput="ngModel"
                                aria-label="Program Certification Period"
                                name="programCertInput"
                                required>
                                <mat-error *ngIf="programCertInput.hasError('required')">
                                    <div ng-message="required">This field is required</div>
                                </mat-error>
                        </mat-form-field>
                        <mat-form-field class="recertPeriodUnit" appearance="outline">
                            <mat-select 
                                ngModel
                                [(ngModel)]="selectedProgram.recertPeriodUnit"
                                aria-label="Program Certification Unit"
                                placeholder="Choose One" 
                                id="programCertUnit"
                                #programCertUnit="ngModel"
                                name="programCertUnit"
                                required>
                                <mat-option value="Days">Days</mat-option>
                                <mat-option value="Months">Months</mat-option>
                                <mat-option value="Years">Years</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>

                <div class="section-header chevronAlign"  *ngIf="selectedProgram.programType !== 'Loan' && selectedProgram.programType !== 'Misc'">
                    <a class="account__details-collapse">
                        <mat-icon class="collapse-icon" 
                            [ngClass]="{'collapse-icon--open' : monthlyChargeInfoPanelOpenState}"
                            (click)="monthlyChargeInfoPanelOpenState =!monthlyChargeInfoPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="headerPadding">Monthly Charge Calculation Information</div>
                </div>
                <mat-expansion-panel [expanded]="monthlyChargeInfoPanelOpenState" class="hidePanel" *ngIf="selectedProgram.programType !== 'Loan' && selectedProgram.programType !== 'Misc'">
                    <app-program-rent-calc
                        *ngIf="selectedProgram.programSettings"
                        [(rentCalc)]="selectedProgram.programSettings" 
                        [programId]="selectedProgram.id" 
                        [programType]="selectedProgram.programType"
                        [housingAuthority]="housingAuthority"
                    ></app-program-rent-calc>
                </mat-expansion-panel>
                
                <div class="section-header chevronAlign">
                    <a class="account__details-collapse">
                        <mat-icon class="collapse-icon"
                            [ngClass]="{'collapse-icon--open' : customAttributesInfoPanelOpenState}"
                            (click)="customAttributesInfoPanelOpenState = !customAttributesInfoPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="headerPadding">Custom Attributes</div>
                </div>
                <mat-expansion-panel [expanded]="customAttributesInfoPanelOpenState" class="hidePanel">
                    <div *ngIf="!isBusy">
                        <div class="section-body" layout-align="start center">
                            <p *ngIf="attributeCount === 0">No custom attributes apply to this Program</p>
                            <app-custom-attributes *ngIf="attributeCount > 0"  
                                [entity]="'Program'" 
                                [editMode]="true" 
                                [(currentCustomAttributes)]="customAttributes"
                                [sqlData]="true"
                                (currentCustomAttributesChange)="attributeChangeHandler($event)">
                            </app-custom-attributes>
                        </div>
                    </div>
                </mat-expansion-panel>

            </div>

                <div class="section-header chevronAlign">
                    <a class="account__details-collapse">
                        <mat-icon class="collapse-icon" 
                            [ngClass]="{'collapse-icon--open' : accountingInfoPanelOpenState}"
                            (click)="accountingInfoPanelOpenState =!accountingInfoPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="headerPadding">
                        Accounting Information
                    </div>
                </div> 
                <mat-expansion-panel [expanded]="accountingInfoPanelOpenState" class="hidePanel">
                    <div *ngIf="this.loading">
                        <mat-spinner diameter="25" class="centered_spinner"> </mat-spinner> Loading ledger data
                    </div>

               
                    
                    <div has-permission="'admin'" *ngIf="!this.loading">
                        <div class="section-header chevronAlign">
                            <a class="account__details-collapse">
                                <mat-icon class="collapse-icon" 
                                    [ngClass]="{'collapse-icon--open' : tenantLedgerPanelOpenState}"
                                    (click)="tenantLedgerPanelOpenState =!tenantLedgerPanelOpenState">chevron_right
                                </mat-icon>
                            </a>
                            <div class="layout-row rowPaddingLeft layout-align-start-center moveButton">
                                <div class="headerPadding">
                                    Tenant Ledgers
                                </div>
                                <button mat-flat-button 
                                    type="button"
                                    class="hds_button" 
                                    (click)="createNewAccountDefinition('Tenant')">
                                    Add Account
                                </button>
                            </div>
                        </div>
                        <mat-expansion-panel [expanded]="tenantLedgerPanelOpenState" class="hidePanel">
                            <p class="headerPadding" *ngIf="!tenantLedgers || !tenantLedgers.length">There are no Tenant
                                Ledgers defined
                            </p>
                            <div *ngIf="!this.loading" class="paddingTable">
                                <table class="table altRows" *ngIf="tenantLedgers && tenantLedgers.length > 0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Subledger Mapping</th>
                                            <th>Rules</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tr class="centerData" *ngFor="let account of tenantLedgers"> 
                                        <td> <h4><a (click)="editAccountDefinition(account, 'Tenant')">{{account.name}}</a></h4></td>
                                        <td>
                                            <span class="flex-50" *ngIf="account.chartOfAccountsName">{{account.chartOfAccountsName}}</span>
                                            <span class="flex-50 color-red" *ngIf="!account.chartOfAccountsName">Must select a Subledger
                                                Mapping*.</span>
                                        </td>
                                        <td>
                                            <div class="layout-column" *ngIf="account.id">
                                                <div *ngFor="let rule of getNonDeletedActivityRules(account)">
                                                    <a (click)="editActivityRule(account, rule)">{{rule.displayName}}</a>
                                                </div>
                                                <a (click)="newActivityRule(account)">add</a>
                                            </div>
                                            <div class="layout-column" *ngIf="!account.id">
                                                Please save the program first to add Activity Rules
                                            </div> 
                                        </td>
                                        <td>
                                            <a class="marg-auto" popover-trigger="mouseenter"
                                            (click)="deleteAccountTemplate(account)">
                                                <mat-icon *ngIf="selectedProgram.type === 'Assistance' || tenantLedgers.length > 1  || !account.isPrimary && !account.isPrincipal">delete</mat-icon>
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>            
                        </mat-expansion-panel>
                        
                        <div class="section-header chevronAlign">
                            <a class="account__details-collapse">
                                <mat-icon class="collapse-icon" 
                                    [ngClass]="{'collapse-icon--open' : landlordLedgerPanelOpenState}"
                                    (click)="landlordLedgerPanelOpenState =!landlordLedgerPanelOpenState">chevron_right
                                </mat-icon>
                            </a>
                            <div class="layout-row rowPaddingLeft layout-align-start-center moveButton">
                                <div class="headerPadding">
                                    Landlord Ledger
                                </div>
                                <button 
                                    [disabled]="landlordLedger && landlordLedger.length > 0" 
                                    mat-flat-button 
                                    type="button"
                                    class="hds_button" 
                                    (click)="createNewAccountDefinition('Landlord')">
                                    Add Account
                                </button>
                            </div>
                        </div>
                        <mat-expansion-panel [expanded]="landlordLedgerPanelOpenState" class="hidePanel">
                            <p class="headerPadding" *ngIf="!landlordLedger || !landlordLedger.length">There are no Landlord
                                Ledgers defined
                            </p>
                            <div *ngIf="!this.loading" class="paddingTable">
                                <table class="table altRows" *ngIf="landlordLedger && landlordLedger.length > 0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Subledger Mapping</th>
                                            <th>Rules</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tr class="centerData" *ngFor="let account of landlordLedger"> 
                                        <td>  <h4><a (click)="editAccountDefinition(account, 'Landlord')">{{account.name}}</a></h4></td>
                                        <td>
                                            <span class="flex-50" *ngIf="account.chartOfAccountsName">{{account.chartOfAccountsName}}</span>
                                            <span class="flex-50 color-red" *ngIf="!account.chartOfAccountsName">Must select a Subledger
                                                Mapping*.</span>
                                        </td>
                                        <td>
                                            <div class="layout-column">
                                                <div *ngFor="let rule of getNonDeletedActivityRules(account)">
                                                    <a (click)="editActivityRule(account, rule)">{{rule.displayName}}</a>
                                                </div>
                                                <a (click)="newActivityRule(account)">add</a>
                                            </div>
                                        </td>
                                        <td>
                                            <a class="marg-auto" popover-trigger="mouseenter"
                                            (click)="deleteAccountTemplate(account)">
                                                <mat-icon *ngIf="selectedProgram.type === 'Assistance' || !account.isPrimary && !account.isPrincipal">delete</mat-icon>
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </mat-expansion-panel>

                        <div class="section-header chevronAlign">
                            <a class="account__details-collapse">
                                <mat-icon class="collapse-icon" 
                                    [ngClass]="{'collapse-icon--open' : utilityLedgerPanelOpenState}"
                                    (click)="utilityLedgerPanelOpenState =!utilityLedgerPanelOpenState">chevron_right
                                </mat-icon>
                            </a>
                            <div class="layout-row rowPaddingLeft layout-align-start-center moveButton">
                                <div class="headerPadding">
                                    Utility Ledger
                                </div>
                                <button 
                                    [disabled]="utilityLedger && utilityLedger.length > 0" 
                                    mat-flat-button 
                                    type="button"
                                    class="hds_button" 
                                    (click)="createNewAccountDefinition('Utility')">
                                    Add Account
                                </button>
                            </div>
                        </div>
                        <mat-expansion-panel [expanded]="utilityLedgerPanelOpenState" class="hidePanel">
                            <p class="headerPadding" *ngIf="!utilityLedger || !utilityLedger.length">There are no Utility
                                Ledgers defined
                            </p>
                            <div *ngIf="!this.loading" class="paddingTable">
                                <table class="table altRows" *ngIf="utilityLedger && utilityLedger.length > 0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Subledger Mapping</th>
                                            <th>Rules</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tr class="centerData" *ngFor="let account of utilityLedger"> 
                                        <td>  <h4><a (click)="editAccountDefinition(account, 'Utility')">{{account.name}}</a></h4></td>
                                        <td>
                                            <span class="flex-50" *ngIf="account.chartOfAccountsName">{{account.chartOfAccountsName}}</span>
                                            <span class="flex-50 color-red" *ngIf="!account.chartOfAccountsName">Must select a Subledger
                                                Mapping*.</span>
                                        </td>
                                        <td>
                                            <div class="layout-column">
                                                <div *ngFor="let rule of getNonDeletedActivityRules(account)">
                                                    <a (click)="editActivityRule(account, rule)">{{rule.displayName}}</a>
                                                </div>
                                                <a (click)="newActivityRule(account)">add</a>
                                            </div>
                                        </td>
                                        <td>
                                            <a class="marg-auto" popover-trigger="mouseenter"
                                            (click)="deleteAccountTemplate(account)">
                                                <mat-icon *ngIf="selectedProgram.type === 'Assistance' || !account.isPrimary && !account.isPrincipal">delete</mat-icon>
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </mat-expansion-panel>

                        <div class="section-header chevronAlign">
                            <a class="account__details-collapse">
                                <mat-icon class="collapse-icon" 
                                    [ngClass]="{'collapse-icon--open' : maintenanceLedgerPanelOpenState}"
                                    (click)="maintenanceLedgerPanelOpenState =!maintenanceLedgerPanelOpenState">chevron_right
                                </mat-icon>
                            </a>
                            <div class="layout-row rowPaddingLeft layout-align-start-center moveButton">
                                <div class="headerPadding">
                                    Maintenance Ledger
                                </div>
                                <button 
                                    [disabled]="maintenanceLedger && maintenanceLedger.length > 0" 
                                    mat-flat-button 
                                    type="button"
                                    class="hds_button" 
                                    (click)="createNewAccountDefinition('Maintenance')">
                                    Add Account
                                </button>
                            </div>
                        </div>
                        <mat-expansion-panel [expanded]="maintenanceLedgerPanelOpenState" class="hidePanel">
                            <p class="headerPadding" *ngIf="!maintenanceLedger || !maintenanceLedger.length">There are no Maintenance
                                Ledgers defined
                            </p>
                            <div *ngIf="!this.loading" class="paddingTable">
                                <table class="table altRows" *ngIf="maintenanceLedger && maintenanceLedger.length > 0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Subledger Mapping</th>
                                            <th>Rules</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tr class="centerData" *ngFor="let account of maintenanceLedger"> 
                                        <td>  <h4><a (click)="editAccountDefinition(account, 'Maintenance')">{{account.name}}</a></h4></td>
                                        <td>
                                            <span class="flex-50" *ngIf="account.chartOfAccountsName">{{account.chartOfAccountsName}}</span>
                                            <span class="flex-50 color-red" *ngIf="!account.chartOfAccountsName">Must select a Subledger
                                                Mapping*.</span>
                                        </td>
                                        <td>
                                            <div class="layout-column">
                                                <div *ngFor="let rule of getNonDeletedActivityRules(account)">
                                                    <a (click)="editActivityRule(account, rule)">{{rule.displayName}}</a>
                                                </div>
                                                <a (click)="newActivityRule(account)">add</a>
                                            </div>
                                        </td>
                                        <td>
                                            <a class="marg-auto" popover-trigger="mouseenter"
                                            (click)="deleteAccountTemplate(account)">
                                                <mat-icon *ngIf="selectedProgram.type === 'Assistance' || !account.isPrimary && !account.isPrincipal">delete</mat-icon>
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </mat-expansion-panel>

                        <div class="section-header chevronAlign">
                            <a class="account__details-collapse">
                                <mat-icon class="collapse-icon" 
                                    [ngClass]="{'collapse-icon--open' : housingauthorityLedgerPanelOpenState}"
                                    (click)="housingauthorityLedgerPanelOpenState =!housingauthorityLedgerPanelOpenState">chevron_right
                                </mat-icon>
                            </a>
                            <div class="layout-row rowPaddingLeft layout-align-start-center moveButton">
                                <div class="headerPadding">
                                    Housing Authority Ledger
                                </div>
                                <button 
                                    [disabled]="housingauthorityLedger && housingauthorityLedger.length > 0" 
                                    mat-flat-button 
                                    type="button"
                                    class="hds_button" 
                                    (click)="createNewAccountDefinition('Housingauthority')">
                                    Add Account
                                </button>
                            </div>
                        </div>
                        <mat-expansion-panel [expanded]="housingauthorityLedgerPanelOpenState" class="hidePanel">
                            <p class="headerPadding" *ngIf="!housingauthorityLedger || !housingauthorityLedger.length">There are no Housing Authority
                                Ledgers defined
                            </p>
                            <div *ngIf="!this.loading" class="paddingTable">
                                <table class="table altRows" *ngIf="housingauthorityLedger && housingauthorityLedger.length > 0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Subledger Mapping</th>
                                            <th>Rules</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tr class="centerData" *ngFor="let account of housingauthorityLedger"> 
                                        <td>  <h4><a (click)="editAccountDefinition(account, 'Housingauthority')">{{account.name}}</a></h4></td>
                                        <td>
                                            <span class="flex-50" *ngIf="account.chartOfAccountsName">{{account.chartOfAccountsName}}</span>
                                            <span class="flex-50 color-red" *ngIf="!account.chartOfAccountsName">Must select a Subledger
                                                Mapping*.</span>
                                        </td>
                                        <td>
                                            <div class="layout-column">
                                                <div *ngFor="let rule of getNonDeletedActivityRules(account)">
                                                    <a (click)="editActivityRule(account, rule)">{{rule.displayName}}</a>
                                                </div>
                                                <a (click)="newActivityRule(account)">add</a>
                                            </div>
                                        </td>
                                        <td>
                                            <a class="marg-auto" popover-trigger="mouseenter"
                                            (click)="deleteAccountTemplate(account)">
                                                <mat-icon *ngIf="selectedProgram.type === 'Assistance' || !account.isPrimary && !account.isPrincipal">delete</mat-icon>
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </mat-expansion-panel>

                        <div class="section-header chevronAlign">
                            <a class="account__details-collapse">
                                <mat-icon class="collapse-icon" 
                                    [ngClass]="{'collapse-icon--open' : otherLedgerPanelOpenState}"
                                    (click)="otherLedgerPanelOpenState =!otherLedgerPanelOpenState">chevron_right
                                </mat-icon>
                            </a>
                            <div class="layout-row rowPaddingLeft layout-align-start-center moveButton">
                                <div class="headerPadding">
                                    Other Ledger
                                </div>
                                <button 
                                    [disabled]="otherLedger &&  otherLedger.length > 0" 
                                    mat-flat-button 
                                    type="button"
                                    class="hds_button" 
                                    (click)="createNewAccountDefinition('Other')">
                                    Add Account
                                </button>
                            </div>
                        </div>
                        <mat-expansion-panel [expanded]="otherLedgerPanelOpenState" class="hidePanel">
                            <p class="headerPadding" *ngIf="!otherLedger || !otherLedger.length">There are no Other
                                Ledgers defined
                            </p>
                            <div *ngIf="!this.loading" class="paddingTable">
                                <table class="table altRows" *ngIf="otherLedger && otherLedger.length > 0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Subledger Mapping</th>
                                            <th>Rules</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tr class="centerData" *ngFor="let account of otherLedger"> 
                                        <td>  <h4><a (click)="editAccountDefinition(account, 'Other')">{{account.name}}</a></h4></td>
                                        <td>
                                            <span class="flex-50" *ngIf="account.chartOfAccountsName">{{account.chartOfAccountsName}}</span>
                                            <span class="flex-50 color-red" *ngIf="!account.chartOfAccountsName">Must select a Subledger
                                                Mapping*.</span>
                                        </td>
                                        <td>
                                            <div class="layout-column">
                                                <div *ngFor="let rule of getNonDeletedActivityRules(account)">
                                                    <a (click)="editActivityRule(account, rule)">{{rule.displayName}}</a>
                                                </div>
                                                <a (click)="newActivityRule(account)">add</a>
                                            </div>
                                        </td>
                                        <td>
                                            <a class="marg-auto" popover-trigger="mouseenter"
                                            (click)="deleteAccountTemplate(account)">
                                                <mat-icon *ngIf="selectedProgram.type === 'Assistance' || !account.isPrimary && !account.isPrincipal">delete</mat-icon>
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </mat-expansion-panel>
                    </div>

                    <div>
                        <div class="section-header chevronAlign">
                            <a class="account__details-collapse">
                                <mat-icon class="collapse-icon" 
                                    [ngClass]="{'collapse-icon--open' : monthlyTransactionBehaviorPanelOpenState}"
                                    (click)="monthlyTransactionBehaviorPanelOpenState =!monthlyTransactionBehaviorPanelOpenState">chevron_right
                                </mat-icon>
                            </a>
                            <div class="headerPadding">
                                Monthly Transaction Behavior
                            </div>
                        </div>
                        <mat-expansion-panel [expanded]="monthlyTransactionBehaviorPanelOpenState" *ngIf="monthlyTransactionBehaviorPanelOpenState" class="hidePanel">
                                <app-program-enhanced-rent
                                [(enhancedRentsTrx)]="enhancedRentsTrx"
                                [(enhancedRentsAdj)]="enhancedRentsAdj"
                                [program]="selectedProgram"
                                [tenantLedgers]="tenantLedgers"
                                [landlordLedger]="landlordLedger"
                                [utilityLedger]="utilityLedger"
                                [maintenanceLedger]="maintenanceLedger"
                                [housingauthorityLedger]="housingauthorityLedger"
                                [otherLedger]="otherLedger"
                                [subLedgerMaps]="subLedgerMaps"
                                (enhancedRentChanged)="enhancedRentChanged()">
                                </app-program-enhanced-rent>
                        </mat-expansion-panel> 
                    </div>
                </mat-expansion-panel>

                <div class="section-header chevronAlign">
                    <a class="account__details-collapse">
                        <mat-icon class="collapse-icon" 
                            [ngClass]="{'collapse-icon--open' : chargeConfigurationInfoPanelOpenState}"
                            (click)="chargeConfigurationInfoPanelOpenState =!chargeConfigurationInfoPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="headerPadding">
                        Automatic Charge Configurations
                    </div>
                </div>

            
                <mat-expansion-panel [expanded]="chargeConfigurationInfoPanelOpenState" class="hidePanel" has-permission="'admin'"
                    *ngIf="selectedProgram.accountDefinitions && selectedProgram.accountDefinitions.length > 0 && selectedProgram.type != 'Misc'">
                    <div class="section-header">Work Order Tenant Charges</div>
                    <div class="layout-row layout-align-start-center section-body">
                        <mat-form-field class="workOrderAcctDef" appearance="outline">
                            <mat-label>Select Tenant Account Definition</mat-label>
                            <mat-select
                                ngModel
                                [(ngModel)]="selectedAccountDef"
                                (ngModelChange)="chartOfAccountsSelected(selectedAccountDef)"
                                id="selectedAccountDef"
                                name="selectedAccountDef">
                                <mat-option [value] = "undefined" id = "none"> none </mat-option>
                                <mat-option *ngFor="let accountDef of selectedProgram.accountDefinitions" [value]="accountDef">
                                    {{accountDef.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="workOrderSubcategory" appearance="outline" *ngIf="selectedChartOfAccounts">
                            <mat-label>Select SubCategory</mat-label>
                                <mat-select
                                    ngModel
                                    [(ngModel)]="workOrderAutoChargeConfiguration"
                                    (ngModelChange)="addWorkOrderAutoChargeConfiguration(workOrderAutoChargeConfiguration)"
                                    id="selectedSubCategory"
                                    [compareWith]="compareWithSubCategory"
                                    name="selectedSubCategory">
                                    <mat-optgroup *ngFor="let category of selectedChartOfAccounts.chargeCategories"
                                        label="{{category.name}}">
                                        <mat-option *ngFor="let subCategory of category.subCategories"
                                            ng-selected="subCategory.chartOfAccountsId === workOrderAutoChargeConfiguration.chartOfAccountsId && subCategory.name === workOrderAutoChargeConfiguration.subCategoryName"
                                            [value]="{
                                                subCategoryName: subCategory.name,
                                                accountDefinitionId: selectedAccountDef.id,
                                                categoryName: category.name,
                                                subCategoryId: subCategory._id,
                                                type: 'Work Order Tenant Charge',
                                                categoryId: subCategory.categoryId,
                                                chartOfAccountsId: subCategory.chartOfAccountsId,
                                                chartOfAccountsName: subCategory.chartOfAccountsName,
                                                programId: selectedProgram.id,
                                                id: workOrderAutoChargeConfiguration?.id
                                                    }">{{subCategory.name}}
                                        </mat-option>
                                    </mat-optgroup>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>

        </mat-card-content>
        <mat-card-actions>
            <button mat-flat-button class="hds_button cancel" (click)="closeDialog()" id="cancelButton">Cancel</button>
            <button mat-flat-button class="hds_button" (click)="save()" id="saveButton" 
                [disabled]="!editProgramForm.valid 
                || editProgramForm.pristine
                || isSaving ">
                {{saveButtonText()}} <mat-spinner *ngIf="isSaving" diameter="18" > </mat-spinner>
            </button>
        </mat-card-actions>
    </form>
</mat-card>
