import {DateTime} from 'aws-sdk/clients/ssm';
import {
  BsonEntityId,
  BsonModifiedBy,
  BsonDeletedBy,
  BsonArchivedBy,
  CustomAttribute,
  Note,
  CustomAttributes,
  ICustomAttributesValue,
} from '../../../core/service/core-models';
import {TransactionRequestType, HouseholdAction, AccountDefinition, ISubLedgerMap} from '../../accounting/service/accounting-models';
import {Address} from './member.models';
import {forEach} from 'lodash';
import {LandlordPropertiesComponent} from '../../occupancy/vendors/landlord-properties/landlord-properties.component';
import {initFunction} from 'src/app/app.module';
import {getMatIconFailedToSanitizeLiteralError} from '@angular/material/icon';
import {ScrollStrategy} from '@angular/cdk/overlay';
import {AutoChargeConfigurationValues, ProgramModel} from 'src/app/shared/services/program.model';

// export interface UnassignmentRequest {
//   unitId: string;
//   unitAssignmentId: string;
//   householdId: string;
//   modifiedBy: string;
// }

export interface AssignmentRequest {
  minDate: Date;
  selectedUnit: string;
  effectiveDate: Date;
  isRentalAssistance: boolean;
  rentCalc: number;
  projectedRent: number;
  tenantPayment: number;
  hapAmount: number;
  fixedRentReason: string;
  contractRent: number;
  todayDate: Date;
}

export interface IEffectiveByBedroom extends BsonEntityId, BsonModifiedBy {
  effectiveDate?: string;
  unitImportOverride?: boolean;
  value?: number[]; // array where index is the number of bedrooms
  id?: string;
}

export class EffectiveByBedroom implements IEffectiveByBedroom {
  /**
   *
   */
  constructor(unitFee: UnitFee, numberOfBedrooms: number) {
    this.effectiveDate = unitFee.effectiveDate;
    const valueArray = Array.from({length: 5}, () => 0);
    valueArray[numberOfBedrooms] = unitFee.amount;
    this.value = valueArray;
    this.id = unitFee.id;
  }
  effectiveDate?: string;
  id?: string;
  value?: number[]; // array where index is the number of bedrooms
}

export interface RentCalc {
  household: string;
  unit: string;
  tenantPaymentAmount: number;
  contractRentAmount: number;
  housingAssistancePaymentAmount: number;
  split1: number;
  split2: number;
  split3: number;
  warning: string;
}

export interface IRentCalcDetails extends BsonEntityId {
  unitImportOverride?: boolean;
  programType?: string;
  calcKey?: string;
  assistanceCalcKey?: string;
  elderlyDeductionAge?: number;
  allowableElderlyDisabilityDeduction?: number;
  allowableDependentDeduction?: number;
  adjustedIncomePercentage?: number;
  totalTenantPaymentPercentage?: number;
  principleReduced?: number;
  lateFeeAmount?: number;
  monthOfFiscalYearEnd?: number;
  nearElderAge?: number;
  fundingSource?: string;
  mincProjectName?: string;
  mincProjectNumber?: number;
  siteId?: number;
  minTtp?: number;
  borrowerId?: string;
  basicRent?: number;
  noteRateRent?: number;
  utilityAllowance?: number;
  plan?: string;
  percentAdjustedIncomeMonthlyCharge?: number;
  binNumber?: string;
  adminFees?: IEffectiveByBedroom[];
  flatRents?: IEffectiveByBedroom[];
  minimumCharges?: IEffectiveByBedroom[];
  maximumCharges?: IEffectiveByBedroom[];
  rentLimitArea?: string;
  noteRateRents?: IEffectiveByBedroom[];
  utilityAllowances?: IEffectiveByBedroom[];
  fairMarketRents?: IEffectiveByBedroom[];
  basicRents?: IEffectiveByBedroom[];
  paymentStandardAmount?: number;
  observeNahasda30Percent?: boolean;
  incomeBased?: boolean;
  minimumMepaBalance?: number;
  conveyanceEligibilityCharge?: number;
  incomeLimitArea?: string;
  taxCreditIncomeLimitArea?: string;
  paymentStandard?: string;
  imputedAssetRate?: number;
  familySubsidyStatus?: string;
  isIncomeBasedRent?: boolean;
  requiresHotmaUpdates?: boolean;
  isUtilityAllowanceCalculations?: boolean;
  minTTP?: number;
}

export class UnitRentCalc implements IRentCalcDetails {
  /**
   *
   */
  constructor(unit: Unit) {
    const rentCalc = unit.unitOverrides;
    this.calcKey = rentCalc.calcKey;
    this.assistanceCalcKey = rentCalc.assistanceCalcKey;
    this.elderlyDeductionAge = rentCalc.elderlyDeductionAge;
    this.allowableElderlyDisabilityDeduction = rentCalc.allowableElderlyDisabilityDeduction;
    this.allowableDependentDeduction = rentCalc.allowableDependentDeduction;
    this.totalTenantPaymentPercentage = rentCalc.totalTenantPaymentPercentage;
    this.nearElderAge = rentCalc.nearElderAge;
    this.minTtp = rentCalc.minTtp;
    this.observeNahasda30Percent = rentCalc.observeNahasda30Percent;
    this.incomeBased = rentCalc.incomeBased;
    this.incomeLimitArea = rentCalc.incomeLimitAreaId;
    this.paymentStandard = rentCalc.paymentStandardId;
    this.taxCreditIncomeLimitArea = rentCalc.taxCreditIncomeLimitAreaId;
    this.rentLimitArea = rentCalc.rentLimitAreaId;
    this._id = rentCalc.id;
    this.populateFees(unit);
  }
  private populateFees(unit: Unit) {
    this.adminFees = [];
    this.flatRents = [];
    this.minimumCharges = [];
    this.maximumCharges = [];
    this.noteRateRents = [];
    this.utilityAllowances = [];
    this.fairMarketRents = [];
    this.basicRents = [];
    for (const unitFee of unit.unitOverrides.unitFees) {
      const effectiveByBedroom: EffectiveByBedroom = new EffectiveByBedroom(unitFee, unit.numberOfBedrooms);
      try {
        this[this.lowerCaseFirstLetter(unitFee.feeTableType)].push(effectiveByBedroom);
      } catch (error) {
        console.log(error);
      }
    }
  }
  private lowerCaseFirstLetter(string): string {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }
  _id?: string;
  calcKey?: string;
  assistanceCalcKey?: string;
  elderlyDeductionAge?: number;
  allowableElderlyDisabilityDeduction?: number;
  allowableDependentDeduction?: number;
  totalTenantPaymentPercentage?: number;
  nearElderAge?: number;
  minTtp?: number;
  observeNahasda30Percent?: boolean;
  incomeBased?: boolean;
  incomeLimitArea?: string;
  taxCreditIncomeLimitArea?: string;
  paymentStandard?: string;
  rentLimitArea?: string;
  adminFees?: IEffectiveByBedroom[];
  flatRents?: IEffectiveByBedroom[];
  minimumCharges?: IEffectiveByBedroom[];
  maximumCharges?: IEffectiveByBedroom[];
  noteRateRents?: IEffectiveByBedroom[];
  utilityAllowances?: IEffectiveByBedroom[];
  fairMarketRents?: IEffectiveByBedroom[];
  basicRents?: IEffectiveByBedroom[];
}

export interface IUnitFee {
  id: string;
  unitOverridesId: string;
  feeTableType: string;
  effectiveDate: string;
  amount: number;
}

export class UnitFee implements IUnitFee {
  /**
   *
   */
  constructor(fee: IEffectiveByBedroom, feeType: string, unit: Unit, feeId = '') {
    this.feeTableType = feeType;
    this.effectiveDate = fee.effectiveDate;
    this.amount = fee.value[unit.numberOfBedrooms];
    this.unitOverridesId = unit.unitOverrides.id;
    this.id = feeId;
  }
  id: string;
  unitOverridesId: string;
  feeTableType: string;
  effectiveDate: string;
  amount: number;
}

export interface IUnitOverride {
  id?: string;
  calcKey?: string;
  assistanceCalcKey?: string;
  elderlyDeductionAge?: number;
  allowableElderlyDisabilityDeduction?: number;
  allowableDependentDeduction?: number;
  totalTenantPaymentPercentage?: number;
  nearElderAge?: number;
  observeNahasda30Percent?: boolean;
  incomeBased?: boolean;
  incomeLimitAreaId?: string;
  taxCreditIncomeLimitAreaId?: string;
  paymentStandardId?: string;
  rentLimitAreaId?: string;
  minTtp?: number;
  unitFees: IUnitFee[];
}

export class UnitOverride implements IUnitOverride {
  constructor(rentcalc: IRentCalcDetails) {
    this.calcKey = rentcalc.calcKey;
    this.assistanceCalcKey = rentcalc.assistanceCalcKey;
    this.elderlyDeductionAge = rentcalc.elderlyDeductionAge;
    this.allowableDependentDeduction = rentcalc.allowableDependentDeduction;
    this.allowableElderlyDisabilityDeduction = rentcalc.allowableElderlyDisabilityDeduction;
    this.totalTenantPaymentPercentage = rentcalc.totalTenantPaymentPercentage;
    this.nearElderAge = rentcalc.nearElderAge;
    this.observeNahasda30Percent = rentcalc.observeNahasda30Percent;
    this.incomeBased = rentcalc.incomeBased;
    this.incomeLimitAreaId = rentcalc.incomeLimitArea;
    this.taxCreditIncomeLimitAreaId = rentcalc.taxCreditIncomeLimitArea;
    this.paymentStandardId = rentcalc.paymentStandard;
    this.rentLimitAreaId = rentcalc.rentLimitArea;
    this.minTtp = rentcalc.minTtp;
  }
  private buildUnitFees(rentcalc: IRentCalcDetails, unit: Unit) {
    // for (const adminFees of rentcalc.adminFees) {

    // }
    for (const minRent of rentcalc.minimumCharges) {
      const fee: IUnitFee = new UnitFee(minRent, 'minimumCharges', unit);
      this.unitFees.push(fee);
    }
  }
  id: string;
  calcKey?: string;
  assistanceCalcKey?: string;
  elderlyDeductionAge?: number;
  allowableElderlyDisabilityDeduction?: number;
  allowableDependentDeduction?: number;
  totalTenantPaymentPercentage?: number;
  nearElderAge?: number;
  observeNahasda30Percent?: boolean;
  incomeBased?: boolean;
  incomeLimitAreaId?: string;
  taxCreditIncomeLimitAreaId?: string;
  rentLimitAreaId?: string;
  paymentStandardId?: string;
  minTtp?: number;
  unitFees: IUnitFee[];
}

export interface AutoChargeConfiguration {
  type: 'Work Order Tenant Charge'; // only for work order tenant charges now, but building as enum to allow for flexibility to configure other auto charge configurations in the future
  subCategoryName: string;
  accountDefinition: string;
  categoryName: string;
  subCategoryId: string;
  categoryId: string;
  chartOfAccountsId: string;
  chartOfAccountsName: string;
}

export type ProgramType =
  | 'Assistance'
  | 'Home Ownership'
  | 'Tax Credit'
  | 'Lease Purchase'
  | 'Rural Development'
  | 'Low Rent'
  | 'Loan'
  | 'Misc'
  | 'Public Housing';

export class Program {
  _id?: string;
  id?: string;
  name: string;
  customerId?: string;
  siteId?: string;
  type?: ProgramType;
  programType?: ProgramType;
  aelValue?: number | null;
  programCode?: string | null;
  isHousingProgram?: boolean;
  phaCode?: string;
  accountDefinitions?: AccountDefinition[];
  chartOfAccounts?: string;
  isProjectBasedAccounting?: boolean;
  rentCalc?: IRentCalcDetails;
  housingAuthority?: string;
  legacyKey?: number;
  legacyHousingAuthorityKey?: number;
  recertificationRequired?: boolean;
  isRecertRequired?: boolean;
  recertPeriod?: number;
  recertPeriodUnit?: 'Days' | 'Months' | 'Years';
  autoChargeConfigurations?: AutoChargeConfiguration[];
  autoChargeConfigurationValues?: AutoChargeConfigurationValues[];
  requires58PicForm: boolean;
  enhancedRentToggle?: boolean;
  enhancedRents: EnhancedRentTransactions[];
  customAttributes?: CustomAttribute[] | ICustomAttributesValue[];
  createdBy?: string;
}

export interface IProgram extends BsonEntityId, BsonModifiedBy, BsonArchivedBy {
  _id?: string;
  name: string;
  customerId?: string;
  siteId?: string;
  type?: ProgramType;
  programType?: ProgramType;
  aelValue?: number | null;
  programCode?: string | null;
  isHousingProgram?: boolean;
  phaCode?: string;
  accountDefinitions?: AccountDefinition[];
  chartOfAccounts?: string;
  isProjectBasedAccounting?: boolean;
  rentCalc?: IRentCalcDetails;
  housingAuthority?: string;
  legacyKey?: number;
  legacyHousingAuthorityKey?: number;
  recertificationRequired?: boolean;
  isRecertRequired?: boolean;
  recertPeriod?: number;
  recertPeriodUnit?: 'Days' | 'Months' | 'Years';
  autoChargeConfigurations?: AutoChargeConfiguration[];
  autoChargeConfigurationValues?: AutoChargeConfigurationValues[];
  requires58PicForm: boolean;
  enhancedRentToggle?: boolean;
  enhancedRents: EnhancedRentTransactions[];
  customAttributes?: CustomAttribute[] | ICustomAttributesValue[];
  createdBy?: string;
}
export interface IProgramAttributeCommand extends ICustomerId {
  textValue: string;
  dateTimeValue: DateTime;
  booleanValue: boolean;
}
export interface ICreateProgramAttributeCommand extends IProgramAttributeCommand {
  entityInstanceId: string;
  entityAttributeId: string;
}
export interface IUpdateProgramAttributeCommand extends IProgramAttributeCommand {
  id: string;
}

export interface IDataResponse {
  status: string;
  failureReason?: string;
}
export interface IUpdateProgramDataResponse {
  updateProgram: IProgramDataResponse;
}
export interface ICreateProgramDataResponse {
  createProgram: IProgramDataResponse;
}
export interface IProgramDataResponse extends IDataResponse {
  affectedEntity: IProgram;
}

export interface CreateProgramModal {
  selectedProgramType: ProgramType;
}

export interface ProgramModalData {
  openPanel: UnitUiSections;
  program: IProgram;
  selectedProgram: ProgramModel;
  editMode: boolean;
  // eslint-disable-next-line
  updateFunction: Function;
  // eslint-disable-next-line
  cancelFunction?: Function;
}

export interface ProgramMutationCommand extends ICustomerId {
  name: string;
  type: ProgramType;
  aelValue: number | null;
  programCode: string | null;
  isHousingProgram: boolean;
  phaCode: string;
}

export interface ProgramUpdateCommand extends ProgramMutationCommand {
  updateProgram: any;
  id: string;
  modifiedBy: string;
}
export interface ProgramCreateCommand extends ProgramMutationCommand {
  createdBy: string;
  LegacyId?: string;
  id: string;
}

export enum AccountDefinitionType {
  Tenant = 0,
  Landlord = 1,
  Utility = 2,
  Other = 3,
  Maintenance = 4,
}

export interface EnhancedRentTransactions extends BsonEntityId {
  id?: string;
  ledgerType: AccountDefinitionType;
  accountDefinition: string;
  accountName: string;
  createdDay: string;
  currentMonth: boolean;
  adjustment: boolean;
  transactionType: EnhancedRentTransaction;
  toDelete?: boolean;
}

export interface EnhancedRentTransaction extends BsonEntityId {
  name: string;
  transactionType: string;
  categoryName: string;
  categoryId: string;
  chartOfAccountsId: string;
  chartOfAccountsName: string;
  isRent: boolean;
  isSplit1: boolean;
  isSplit2: boolean;
  isSplit3: boolean;
  isSplit4: boolean;
  isSplit5: boolean;
  isSplit6: boolean;
  isHidden: boolean;
}

export interface ScheduleRecertificationsSchema {
  scheduledDate: string;
  originatingActionId: string;
  unitId: string;
  completedOnDate: string;
  deletedOn: Date;
  isCustom: boolean;
}

export interface RecurringTransaction {
  amount: number;
  account: string;
  effectiveOn: Date;
  stopOn: Date;
  category: string;
  categoryName: string;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
  subCategory: string;
  subCategoryName: string;
  type: 'Charge' | 'Adjustment';
  // eslint-disable-next-line
  recurrence: Object;
  frequency: 'Daily' | 'Weekly' | 'Bi-Weekly' | 'Monthly' | 'Quarterly' | 'Semi-Annually' | 'Annually';
  name: string;
  description: string;
}

export interface HouseholdAccount extends BsonEntityId, BsonModifiedBy {
  name: string;
  actionId: string;
  tenantLedgerName: string;
  program: string;
  project: string;
  programName: string;
  projectName: string;
  accountDefinition: string;
  chartOfAccounts: string;
  displayName: string;
  // eslint-disable-next-line
  typeMetadata: Object;
  isAR: boolean;
  isPrimary: boolean;
  isAccountsPayable: boolean;
  isAssistance: boolean;
  isPrincipal: boolean;
  isDeposit: boolean;
  isRepayment: boolean;
  isBadDebt: boolean;
  unitId: string;
  depositId: string;
  deactivatedOn: Date;
}

export interface Household extends BsonEntityId, BsonModifiedBy {
  householdImportKey: string;
  legacyKey: number;
  legacyHousingAuthorityKey: number;
  familySelfSufficient: boolean;
  recurringTransactions: RecurringTransaction[];
  recertifications: ScheduleRecertificationsSchema[];
  headOfHousehold: string;
  headFirstName: string;
  headLastName: string;
  phoneNumber: string;
  email: string;
  accounts: HouseholdAccount[];
  unitAddress: string;
  unitProgram: string;
  unitProgramName: string;
  unitProject: string;
  unitProjectName: string;
  notes: Note[];
  customAttributes: CustomAttribute[];
  deletedOn: Date;
  deletedBy: string;
  effectiveAction: HouseholdAction<any>;
  effectiveActions: HouseholdAction<any>[];
  // members: Member[],
  // eslint-disable-next-line
  waitingListAssignments: Object;
  // eslint-disable-next-line
  housingAssignments: Object;
  // tenantLedgerReports: TenantLedgeReport[],
  inactive: boolean;
  created: Date;
  user: string;
  householdActions: HouseholdAction<any>[];
}
export interface Project extends BsonEntityId {
  name: string;
  type: string;
  projectNumber?: string;
  projectCode: string;
  program: string;
  programName: string;
  programType: string;
  chartOfAccounts?: string;
  rentCalc?: IRentCalcDetails;
  customAttributes?: CustomAttribute[];
  projectSegmentCode?: string;
  ampProject?: string;
}

export interface ProjectModalData {
  selectedProject: Project;
  programs: Program[];
  housingAuthority: Authority;
  chartOfAccounts?: ISubLedgerMap[];
  customAttributesTemplates: CustomAttributes[];
  editMode: boolean;
  // eslint-disable-next-line
  updateFunction: Function;
}

export interface PersonName {
  first: string;
  middle?: string;
  last: string;
}

export interface UnitStatusHistory extends BsonEntityId, BsonDeletedBy {
  unitStatus: string;
  comment: string;
  tenantName: PersonName;
  householdId: string;
  assignmentId: string;
  effectiveDate: string;
}

export interface IUnit extends BsonEntityId, BsonModifiedBy {
  id?: string;
  ppuCode: string;
  project: string;
  projectName?: string;
  program: string;
  programName?: string;
  unitId?: string;
  unitNumber?: string;
  description?: string;
  streetAddress: string;
  apartmentNumber?: string;
  entranceNumber?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  postalCodePlus4?: string;
  picUnitNumber?: string;
  buildingNumber?: string;
  buildingName?: string;
  country?: string;
  county?: string;
  numberOfBedrooms: number;
  numberOfBathrooms: number;
  isHandicapAccessible: boolean;
  squareFootage?: number;
  yearConstructed?: number;
  is1937Unit?: boolean;
  dateOfFullAvailability?: string;
  isCasExcluded?: boolean;
  dceThresholdExceeded?: Date;
  convertedFrom?: string;
  convertedFromProject?: string;
  bin?: string;
  legalDescription?: string;
  isNonInventoryUnit: boolean;
  latitude?: number;
  longitude?: number;
  photoUrl?: string;
  currentStatus?:
    | 'Vacant'
    | 'Occupied'
    | 'Assigned'
    | 'Conveyance Eligible'
    | 'Conveyed'
    | 'Removed from Inventory'
    | 'Non-dwelling Unit'
    | 'Other';
  statusHistory: UnitStatusHistory[];
  legacyStatusHistory?: UnitStatusHistory[];
  landlord?: string;
  landlordId?: string;
  customAttributes?: CustomAttribute[] | ICustomAttributesValue[];
  notes: Note[];
  rentCalc?: IRentCalcDetails;
  omnisearch?: string[];
  created: Date;
  landlordFirstName?: string;
  landlordLastName?: string;
  landlordCompanyName?: string;
  utilityVendor?: string | string;
  utilityName?: string;
  totalDevelopmentCost?: number;
  __v?: number;
}

export class Unit implements IUnit {
  /**
   *
   */
  constructor() {
    this.id = '';
    this.customerId = '';
    this.siteIdId = '';
    this.overrideRentCalc = false;
    this.customAttributes = [];
    this.ppuCode = '';
    this.project = '';
    this.projectId = '';
    this.projectName = '';
    this.program = '';
    this.programId = '';
    this.programName = '';
    this.unitId = '';
    this.unitNumber = '';
    this.bin = '';
    this.description = '';
    this.streetAddress = '';
    this.apartmentNumber = '';
    this.entranceNumber = '';
    this.city = '';
    this.state = '';
    this.postalCode = '';
    this.postalCodePlus4 = '';
    this.picUnitNumber = '';
    this.propertyId = '';
    this.buildingNumber = '';
    this.buildingName = '';
    this.country = '';
    this.county = '';
    this.numberOfBedrooms = 0;
    this.numberOfBathrooms = 0;
    this.isHandicapAccessible = false;
    this.squareFootage = null;
    this.yearConstructed = null;
    this.is1937Unit = false;
    this.dateOfFullAvailability = '';
    this.isCasExcluded = false;
    this.dceThresholdExceeded = null;
    this.isNonInventoryUnit = false;
    this.landlordId = '';
    this.utilityVendor = '';
    this.totalDevelopmentCost = null;
    this.overrideRentCalc = null;
    this.unitOverrides = null;
    this.rentCalc = null;
    this.utilityVendorId = '';
  }
  id?: string;
  siteIdId?: string;
  customerId?: string;
  ppuCode: string;
  project: string;
  projectId: string;
  projectName?: string;
  program: string;
  programId: string;
  programName?: string;
  unitId?: string;
  unitNumber?: string;
  description?: string;
  streetAddress: string;
  apartmentNumber?: string;
  entranceNumber?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  postalCodePlus4?: string;
  picUnitNumber?: string;
  buildingNumber?: string;
  buildingName?: string;
  propertyId?: string;
  country?: string;
  county?: string;
  numberOfBedrooms: number;
  numberOfBathrooms: number;
  isHandicapAccessible: boolean;
  squareFootage?: number;
  yearConstructed?: number;
  is1937Unit?: boolean;
  dateOfFullAvailability?: string;
  isCasExcluded?: boolean;
  dceThresholdExceeded?: Date;
  convertedFromProgramId?: string;
  convertedFromProjectId?: string;
  bin?: string;
  legalDescription?: string;
  isNonInventoryUnit: boolean;
  latitude?: number;
  longitude?: number;
  photoUrl?: string;
  currentStatus?:
    | 'Vacant'
    | 'Occupied'
    | 'Assigned'
    | 'Conveyance Eligible'
    | 'Conveyed'
    | 'Removed from Inventory'
    | 'Non-dwelling Unit'
    | 'Other';
  statusHistory: UnitStatusHistory[];
  legacyStatusHistory?: UnitStatusHistory[];
  landlord?: string;
  landlordId?: string;
  customAttributes?: CustomAttribute[] | ICustomAttributesValue[];
  notes: Note[];
  rentCalc?: IRentCalcDetails;
  omnisearch?: string[];
  created: Date;
  landlordFirstName?: string;
  landlordLastName?: string;
  landlordCompanyName?: string;
  utilityVendor?: string;
  utilityVendorId?: string;
  utilityName?: string;
  createdOn?: Date;
  createdBy?: string;
  modifiedOn?: Date;
  modifiedBy?: string;
  totalDevelopmentCost?: number;
  unitOverrides?: IUnitOverride;
  overrideRentCalc: boolean;
  unitRealEstateId?: string;
  utilityAllowanceId?: string;
  __v?: number;
}

export interface ICustomerId {
  customerId: string;
  siteId: string;
}

export interface UnitMutationCommand extends ICustomerId {
  description?: string;
  unitNumber?: string;
  apartmentNumber?: string;
  entranceNumber?: string;
  city?: string;
  state?: string;
  postalCode?: number;
  postalCodePlus4?: number;
  county?: string;
  numberOfBedrooms?: number;
  numberOfBathrooms?: number;
  isHandicapAccessible?: boolean;
  isCasExcluded?: boolean;
  squareFootage?: number;
  yearConstructed?: number;
  is1937Unit?: boolean;
  longitude?: number;
  latitude?: number;
  dceThresholdExceeded?: Date;
  createdOn?: Date;
  dateOfFullAvailability?: string;
  photoUrl?: string;
  isNonInventoryUnit?: boolean;
  landlordId?: string;
  convertedFromProgramId?: string;
  convertedFromProjectId?: string;
  bin?: string;
  buildingNumber?: string;
  buildingName?: string;
  picUnitNumber?: string;
  totalDevelopmentCost?: number;
  utilityVendorId?: string;
  housingType?: string;
  customAttributes?: CustomAttribute[];
}

export interface UnitCreateCommand extends UnitMutationCommand {
  programId: string;
  projectId: string;
  streetAddress: string;
  createdBy: string;
}

export interface UnitUpdateCommand extends UnitMutationCommand {
  id: string;
  modifiedBy: string;
  programId?: string;
  projectId?: string;
  streetAddress?: string;
}

export interface IUnitAttributeCommand extends ICustomerId {
  textValue: string;
  dateTimeValue: DateTime;
  booleanValue: boolean;
}

export interface ICreateUnitAttributeCommand extends IUnitAttributeCommand {
  entityInstanceId: string;
  entityAttributeId: string;
}

export interface IUnitOverrideCommand extends ICustomerId {
  calcKey?: string;
  assistanceCalcKey?: string;
  elderlyDeductionAge?: number;
  allowableDependentDeduction?: number;
  totalTenantPaymentPercentage?: number;
  nearElderAge?: number;
  observeNahasda30Percent?: boolean;
  incomeLimitAreaId?: string;
  taxCreditIncomeLimitAreaId?: string;
  rentLimitAreaId?: string;
  monthlyTransactionsCalculatorName?: string;
  paymentStandardId?: string;
  minTTP?: number;
}

export interface ICreateUnitOverrideCommand extends IUnitOverrideCommand {
  unitId: string;
  createdBy: string;
}

export interface IUpdateUnitOverrideCommand extends IUnitOverrideCommand {
  id: string;
  modifiedBy: string;
}

export interface IDeleteUnitOverrideCommand extends ICustomerId {
  id: string;
  deletedBy: string;
}

export interface ICreateUnitFeeCommand extends ICustomerId {
  unitOverridesId: string;
  feeTableType: string;
  effectiveDate: DateTime;
  amount: number;
  createdBy: string;
}

export interface IUpdateUnitFeeCommand extends ICustomerId {
  id: string;
  effectiveDate?: DateTime;
  amount?: number;
  deleteFee?: boolean;
  modifiedBy: string;
}

export interface IDeleteUnitFeeCommand extends ICustomerId {
  id: string;
  deletedBy: string;
}

export interface IUpdateUnitAttributeCommand extends IUnitAttributeCommand {
  id: string;
}

export interface VendorModalData {
  selectedVendor: Vendor;
  vendors: Vendor[];
  // eslint-disable-next-line
  updateFunction: Function;
}

export enum VendorTypes {
  Landlord = 'Landlord',
  Utility = 'Utility',
  Maintenance = 'Maintenance',
  Other = 'Other',
  Housingauthority = 'Housingauthority',
}

export interface VendorNotesModalData {
  selectedNote: Note;
  // eslint-disable-next-line
  updateFunction: Function;
}

export interface VendorEditModalData {
  selectedVendor: Vendor;
  isNew: boolean;
  // eslint-disable-next-line
  updateFunction: Function;
}

export enum VendorPaymentPreferences {
  ACH = 'ACH',
  Check = 'CHECK',
  Other = 'OTHER',
}

export interface IVendor extends BsonEntityId, BsonModifiedBy, BsonArchivedBy {
  id: string; //Guid Id field from SQL
  vendorType: string;
  firstName: string;
  lastName: string;
  payTo: string;
  companyName: string;
  phoneNumber: string;
  emailAddress: string;
  addresses: ILandlordAddress[];
  taxId: string;
  externalReferenceId: string;
  paymentPreference: string;
  notes: VendorNote[];
  customAttributes?: any;
  attributeValues?: AttributeValue[] | any;
  vendorAch: IVendorAch;
  phaCode?: string;
}

export interface IVendorAch extends BsonEntityId, BsonModifiedBy {
  id: string;
  accountNumber: string;
  accountNumberDisplay;
  routingNumber: string;
  checkingAcct: boolean;
  consumerAcct: boolean;
  dataKey: string;
  dataVector: string;
  vendorId: string;
}

export class VendorAch implements VendorAch {
  id: string;
  accountNumber: string;
  accountNumberDisplay: string;
  routingNumber: string;
  checkingAcct: boolean;
  consumerAcct: boolean;
  dataKey: string;
  dataVector: string;
  vendorId: string;
  constructor(vendorAch?: IVendorAch, vendorId = '') {
    if (vendorAch) {
      this.id = vendorAch.id;
      this.accountNumber = `*****${vendorAch.accountNumberDisplay}`;
      this.accountNumberDisplay = vendorAch.accountNumberDisplay;
      this.routingNumber = vendorAch.routingNumber;
      this.dataKey = vendorAch.dataKey;
      this.dataVector = vendorAch.dataVector;
      this.checkingAcct = vendorAch.checkingAcct;
      this.consumerAcct = vendorAch.consumerAcct;
      this.vendorId = vendorAch.vendorId;
    } else {
      this.id = null;
      this.accountNumber = '';
      this.accountNumberDisplay = '';
      this.routingNumber = '';
      this.dataKey = '';
      this.dataVector = '';
      this.checkingAcct = true;
      this.consumerAcct = false;
      this.vendorId = vendorId;
    }
  }
}

export class Vendor implements IVendor {
  id: string;
  vendorType: string;
  firstName: string;
  lastName: string;
  payTo: string;
  companyName: string;
  phoneNumber: string;
  emailAddress: string;
  addresses: ILandlordAddress[];
  taxId: string;
  externalReferenceId: string;
  paymentPreference: string;
  notes: VendorNote[];
  attributeValues?: any;
  vendorAch: IVendorAch;
  phaCode?: string;
  constructor(vendor?: IVendor) {
    if (vendor) {
      this.id = vendor.id;
      this.vendorType = vendor.vendorType;
      this.firstName = vendor.firstName;
      this.lastName = vendor.lastName;
      this.payTo = vendor.payTo;
      this.companyName = vendor.companyName;
      this.phoneNumber = vendor.phoneNumber;
      this.emailAddress = vendor.emailAddress;
      this.addresses =
        vendor.addresses && vendor.addresses.length > 0 ? [new LandlordAddress(vendor.addresses[0])] : [new LandlordAddress()];
      this.taxId = '';
      this.externalReferenceId = '';
      this.paymentPreference = vendor.paymentPreference;
      this.notes = vendor.notes && vendor.notes.length > 0 ? vendor.notes : [];
      this.attributeValues = [];
      this.vendorAch = vendor.vendorAch ? new VendorAch(vendor.vendorAch) : new VendorAch(null, vendor.id);
      this.phaCode = vendor.phaCode ? vendor.phaCode : null;
    } else {
      this.id = '';
      this.vendorType = 'Landlord';
      this.firstName = '';
      this.lastName = '';
      this.companyName = '';
      this.phoneNumber = '';
      this.emailAddress = '';
      this.addresses = [new LandlordAddress()];
      this.taxId = '';
      this.externalReferenceId = '';
      this.paymentPreference = VendorPaymentPreferences.Check.toString();
      this.notes = [];
      this.attributeValues = [];
      this.vendorAch = new VendorAch();
    }
    this.addresses[0].vendorId = this.id;
  }
  customAttributes?: any;
  _id?: string;
  createdOn?: Date;
  createdBy?: string;
  modifiedOn?: Date;
  modifiedBy?: string;
  archivedOn?: Date;
  archivedBy?: string;
}

export interface VendorNote extends Note {
  vendorId: string;
}

export interface VendorMutationCommand extends ICustomerId {
  companyName?: string;
  firstName?: string;
  lastName?: string;
  PayTo?: string;
  emailAddress?: string;
  phoneNumber?: string;
  taxId?: string;
  externalReferenceId?: string;
  importKey?: string;
  phaCode?: string;
}
export interface VendorUpdateCommand extends VendorMutationCommand {
  id: string;
  paymentPreference?: string;
  modifiedBy: string;
}
export interface VendorCreateCommand extends VendorMutationCommand {
  createdBy: string;
  LegacyId?: string;
  paymentPreference: string;
  vendorType: string;
}
export interface VendorAddressUpdateMutation extends ICustomerId {
  unit?: string;
}
export interface VendorAddressUpdateCommand extends VendorAddressUpdateMutation {
  id: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  postalCode?: string;
}
export interface VendorAddressCreateCommand extends VendorAddressUpdateMutation {
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
  vendorId: string;
}
export interface VendorAchUpdateMutation extends ICustomerId {
  consumerAcct: boolean;
  checkingAcct: boolean;
}
export interface VendorAchUpdateCommand extends VendorAchUpdateMutation {
  id: string;
  accountNumberDisplay?: string;
  accountNumber?: string;
  dataKey?: string;
  dataVector?: string;
  routingNumber?: string;
  modifiedBy: string;
}

export interface VendorAchCreateCommand extends VendorAchUpdateMutation {
  vendorId: string;
  accountNumberDisplay: string;
  accountNumber: string;
  dataKey: string;
  dataVector: string;
  routingNumber: string;
  createdBy: string;
}

export interface AttributeValue extends BsonEntityId, BsonModifiedBy {
  id: string;
  siteId: string;
  customerId: string;
  vendorId: string; //Guid Id field from SQL
  attributeValuesId: string;
  entityAttributeId: string;
  entityAttributesName: string;
  entitiesName: string;
  attributeType: string;
  textValue?: string;
  booleanValue?: string;
  dateTimeValue?: Date;
  isActive: boolean;
  isRequired: boolean;
  __typename: string;
}

export interface Landlord {
  siteId: string;
  name: string;
  phoneNumber: string;
  emailAddress: string;
  paymentPreference: string;
  vendorType: string;
  externalReferenceId: string;
}

export interface ILandlordAddress extends BsonEntityId {
  id?: string;
  streetAddress: string;
  unit?: string;
  city: string;
  state: string;
  postalCode: string;
  vendorId: string;
}

export class LandlordAddress implements ILandlordAddress {
  id?: string;
  streetAddress: string;
  unit?: string;
  city: string;
  state: string;
  postalCode: string;
  _id?: string;
  createdOn?: Date;
  createdBy?: string;
  vendorId: string;
  constructor(landlordAddress?: ILandlordAddress) {
    if (landlordAddress) {
      this.id = landlordAddress.id;
      this.streetAddress = landlordAddress.streetAddress;
      this.unit = landlordAddress.unit;
      this.city = landlordAddress.city;
      this.state = landlordAddress.state;
      this.postalCode = landlordAddress.postalCode;
      this.vendorId = landlordAddress.vendorId;
    } else {
      this.id = '';
      this.streetAddress = '';
      this.unit = '';
      this.city = '';
      this.state = '';
      this.postalCode = '';
      this.vendorId = '';
    }
  }
}

export interface OfficeLocationsModalData {
  officeAddresses?: OfficeLocation[];
  selectedLocation?: OfficeLocation;
  editMode?: boolean;
  // eslint-disable-next-line
  updateFunction: Function;
}

export interface OfficeLocation {
  _id?: string;
  name: string;
  address: Address[];
  acceptCash: boolean;
}

export interface IncomeLimitModalData {
  name: string;
  incomeLimitArea?: IncomeLimitArea;
  index?: number;
  // eslint-disable-next-line
  updateFunction: Function;
}

export interface RegionalRentModalData {
  name: string;
  rentLimitArea?: RentLimitArea;
  index?: number;
  // eslint-disable-next-line
  updateFunction: Function;
}

export interface FundingAndDepartmentModalData {
  selectedObject: any;
  type: string;
  editMode?: boolean;
  // eslint-disable-next-line
  updateFunction: Function;
  allObjects: any;
}

export interface Department {
  name: string;
  location: OfficeLocation[];
}
export interface MoveInRequest {
  unit: Unit;
  household: Household;
  project: Project;
}

export interface MoveOutRequest {
  unit: Unit;
  household: Household;
  project: Project;
}

export interface HouseholdModalData {
  household: Household;
  // eslint-disable-next-line
  updateFunction: Function;
}

export interface IncomeValue {
  householdSize: number;
  amount: number;
}

export interface IncomeLevels {
  name: string;
  incomeValues: IncomeValue[];
  percentageOfMedianIncome: number;
}

export interface IncomeLimitTable {
  effectiveDate: Date | string;
  medianIncome: number;
  incomeLevels: IncomeLevels[];
}

export interface IncomeLimitArea extends BsonEntityId {
  name: string;
  housingAuthorityId?: string;
  incomeLimitTables: IncomeLimitTable[];
}

export interface RentValue {
  bedroomSize: number;
  amount: number;
}

export interface RentLimits {
  name: string;
  percentageOfRent: number;
  rentValues: RentValue[];
}

export interface RentLimitTable {
  effectiveDate: Date | string;
  rentLimits: RentLimits[];
}
export interface RentLimitArea {
  name: string;
  rentLimitTables: RentLimitTable[];
}

export interface PaymentStandardTable {
  effectiveDate: Date | string;
  values: RentValue[];
}

export interface PaymentStandard {
  name: string;
  paymentStandardTables: PaymentStandardTable[];
}

export interface DestinationAccount {
  name: string;
  reportName: string;
  categoryName: string;
  postTo: string;
  offsetTo: string;
}

export interface CreditCategory {
  name: string;
  destinationAccounts: DestinationAccount[];
}

export interface ChargeCategory {
  name: string;
  destinationAccounts: DestinationAccount[];
}

export interface PrePaymentAccountSetup {
  chargeCategories: ChargeCategory[];
  creditCategories: CreditCategory[];
  accountCode: string;
}

export interface Authority {
  _id: string;
  name: string;
  accountDefinition: AuthorityAccountDefinition;
  systemName: string;
  address: Address[];
  fedTaxId: number;
  fundingSources: string[];
  rentCalc?: IRentCalcDetails;
  phone: string;
  fax: string;
  email: string;
  phaCode: string;
  landlordId: string;
  timeZoneName: string;
  officeLocations: OfficeLocation[];
  departments: Department[];
  incomeLimitAreas: IncomeLimitArea[];
  rentLimitAreas: RentLimitArea[];
  paymentStandards: PaymentStandard[];
  prePaymentAccountSetup: PrePaymentAccountSetup[];
  accountNumber: string;
  routingNumber: string;
  odfiName: string;
}

export interface AuthorityAccountDefinition extends BsonEntityId {
  name?: string;
  chartOfAccountsName?: string;
  chartOfAccounts?: string;
}

export interface HowseholdLedgerModalData extends HouseholdModalData {
  transactionRequestType: TransactionRequestType;
}

export interface PicUnitStatus extends BsonEntityId, BsonModifiedBy {
  comment: string;
  startDate: Date;
  endDate: Date;
  unitId: string;
  picStatus: string;
  __v: number;
}

export interface PicUnitStatusesModalData {
  picUnitStatuses: PicUnitStatus[];
  selectedPicUnitStatus?: PicUnitStatus;
  editMode?: boolean;
  // eslint-disable-next-line
  updateFunction: Function;
}

export enum UnitUiSections {
  GeneralInfo,
  CustomAttributes,
}
export interface UnitModalData {
  unit: IUnit;
  editMode: boolean;
  programs: Program[];
  projects: Project[];
  housingAuthority: Authority;
  openPanel: UnitUiSections;
  defaultRentCalc: IRentCalcDetails;
  // eslint-disable-next-line
  updateFunction: Function;
  // eslint-disable-next-line
  cancelFunction?: Function;
}

export class IncomeValueModal implements IncomeValue {
  constructor(public householdSize: number, public amount: number) {}
}

export class IncomeLevelModal implements IncomeLevels {
  constructor(public name: string, public percentageOfMedianIncome: number, public incomeValues: IncomeValue[]) {}
}

export class IncomeLimitTableModal implements IncomeLimitTable {
  constructor(public effectiveDate: Date | string, public medianIncome: number, public incomeLevels: IncomeLevels[]) {}
}

export class IncomeLimitAreaModal implements IncomeLimitArea {
  name: string;
  incomeLimitTables: IncomeLimitTable[];
  constructor() {
    this.name = '';
    this.incomeLimitTables = [
      new IncomeLimitTableModal(new Date(), 0, [
        new IncomeLevelModal('100%', 100, [
          new IncomeValueModal(1, 0),
          new IncomeValueModal(2, 0),
          new IncomeValueModal(3, 0),
          new IncomeValueModal(4, 0),
          new IncomeValueModal(5, 0),
          new IncomeValueModal(6, 0),
          new IncomeValueModal(7, 0),
          new IncomeValueModal(8, 0),
        ]),
        new IncomeLevelModal('80%', 80, [
          new IncomeValueModal(1, 0),
          new IncomeValueModal(2, 0),
          new IncomeValueModal(3, 0),
          new IncomeValueModal(4, 0),
          new IncomeValueModal(5, 0),
          new IncomeValueModal(6, 0),
          new IncomeValueModal(7, 0),
          new IncomeValueModal(8, 0),
        ]),
        new IncomeLevelModal('30%', 30, [
          new IncomeValueModal(1, 0),
          new IncomeValueModal(2, 0),
          new IncomeValueModal(3, 0),
          new IncomeValueModal(4, 0),
          new IncomeValueModal(5, 0),
          new IncomeValueModal(6, 0),
          new IncomeValueModal(7, 0),
          new IncomeValueModal(8, 0),
        ]),
      ]),
    ];
  }
}

export class RentValueModal implements RentValue {
  constructor(public bedroomSize: number, public amount: number) {}
}

export class RentLimitModal implements RentLimits {
  constructor(public name: string, public percentageOfRent: number, public rentValues: RentValue[]) {}
}

export class RentLimitTableModal implements RentLimitTable {
  constructor(public effectiveDate: Date, public rentLimits: RentLimitModal[]) {}
}

export class RentLimitAreaModal implements RentLimitArea {
  name: string;
  rentLimitTables: RentLimitTable[];

  constructor() {
    this.name = '';
    this.rentLimitTables = [
      new RentLimitTableModal(new Date(), [
        new RentLimitModal('100%', 100, [
          new RentValueModal(0, 0),
          new RentValueModal(1, 0),
          new RentValueModal(2, 0),
          new RentValueModal(3, 0),
          new RentValueModal(4, 0),
          new RentValueModal(5, 0),
          new RentValueModal(6, 0),
          new RentValueModal(7, 0),
          new RentValueModal(8, 0),
        ]),
        new RentLimitModal('80%', 80, [
          new RentValueModal(0, 0),
          new RentValueModal(1, 0),
          new RentValueModal(2, 0),
          new RentValueModal(3, 0),
          new RentValueModal(4, 0),
          new RentValueModal(5, 0),
          new RentValueModal(6, 0),
          new RentValueModal(7, 0),
          new RentValueModal(8, 0),
        ]),
        new RentLimitModal('30%', 30, [
          new RentValueModal(0, 0),
          new RentValueModal(1, 0),
          new RentValueModal(2, 0),
          new RentValueModal(3, 0),
          new RentValueModal(4, 0),
          new RentValueModal(5, 0),
          new RentValueModal(6, 0),
          new RentValueModal(7, 0),
          new RentValueModal(8, 0),
        ]),
      ]),
    ];
  }
}

export class PaymentStandardValue implements RentValue {
  constructor(public bedroomSize: number, public amount: number) {}
}

export class PaymentStandardTableModal implements PaymentStandardTable {
  constructor(public effectiveDate: Date | string, public values: PaymentStandardValue[]) {}
}

export class PaymentStandardModal implements PaymentStandard {
  name: string;
  paymentStandardTables: PaymentStandardTable[];
  constructor() {
    this.name = '';
    this.paymentStandardTables = [
      new PaymentStandardTableModal(new Date(), [
        new PaymentStandardValue(0, 0),
        new PaymentStandardValue(1, 0),
        new PaymentStandardValue(2, 0),
        new PaymentStandardValue(3, 0),
        new PaymentStandardValue(4, 0),
        new PaymentStandardValue(5, 0),
        new PaymentStandardValue(6, 0),
        new PaymentStandardValue(7, 0),
        new PaymentStandardValue(8, 0),
      ]),
    ];
  }
}
