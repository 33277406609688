<section class="tabs__panel kanso-wizard-container">
    <div class="wizard-header">
        <p>Issue Voucher</p>
        <button mat-icon-button (click)="onCancelIssueVoucher()"><mat-icon>close</mat-icon></button>
    </div>
    <mat-horizontal-stepper class="kanso-wizard vouchers-wizard" #stepper>
        <mat-step *ngFor="let info of stepWizardInfo; let i = index">
            <ng-template matStepLabel>
                <kanso-wizard-header [stepper]="stepper" [position]="i">{{info.stepHeader}}</kanso-wizard-header>
            </ng-template>
            <ng-container [ngSwitch]="info.stepTitle">
                <kanso-wizard-content *ngSwitchCase="'Issue Voucher'">
                    <div class="section-body layout-align-start-center">
                        <p>Voucher Status - issued</p>
                    </div>
                    <form [formGroup]="voucherForm">
                        <div class="layout-row layout-align-start-center">
                            <mat-form-field class="flex-35" appearance="outline"> 
                                <mat-select formControlName="fundingProgram" required (selectionChange)="getProjects()">
                                    <mat-option *ngFor="let fundingprogram of fundingPrograms" [value]="fundingprogram.node">
                                      {{ fundingprogram.node.name }}
                                    </mat-option>
                                  </mat-select>
                            </mat-form-field>
                            <mat-form-field class="flex-35" appearance="outline"> 
                                <mat-select formControlName="fundingProject" required (selectionChange)="preFillVoucherNumber()">
                                    <mat-option *ngFor="let fundingproject of fundingProjects" [value]="fundingproject">
                                      {{ fundingproject.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="check-box flex-30">
                                <mat-checkbox 
                                    formControlName="protectionVoucher">
                                </mat-checkbox>
                                <p>Tenant Protection Voucher</p>
                            </div>
                        </div>

                        <div class="layout-row layout-align-start-center">
                            <mat-form-field class="flex-20" appearance="outline"> 
                                <mat-select formControlName="voucherType">
                                    <mat-option *ngFor="let vouchertype of voucherTypeList" [value]="vouchertype">
                                      {{ vouchertype }}
                                    </mat-option>
                                  </mat-select>
                            </mat-form-field>
                     
                            <mat-form-field class="flex-15" appearance="outline"> 
                                <mat-select formControlName="specialType">
                                    <mat-option *ngFor="let specialtype of voucherSpecialTypeList" [value]="specialtype">
                                      {{ specialtype }}
                                    </mat-option>
                                  </mat-select>
                            </mat-form-field>
                            <mat-form-field class="flex-20" appearance="outline"> 
                                <mat-label>Voucher Number</mat-label>
                                <input matInput formControlName="voucherNumber" required />
                            </mat-form-field>
                            <mat-form-field class="flex-15" appearance="outline"> 
                                <mat-label>Bedrooms</mat-label>
                                <input matInput formControlName="authorizedBedrooms" type="number" required />
                            </mat-form-field>
                            <div class="check-box flex-30" *ngIf="voucherForm.value.protectionVoucher">
                                <mat-checkbox 
                                    formControlName="enhancedVoucher">
                                </mat-checkbox>
                                <p>Enhanced Voucher</p>
                            </div>
                        </div>

                        <div class="layout-row layout-align-start-center">
                            <mat-radio-group class="flex-45" appearance="outline" formControlName="portabilityType">
                                <mat-label style="margin-left: 10px;">Portability Type:</mat-label>
                                <mat-radio-button *ngFor="let portabilityType of voucherPortabilityTypeList" [value]="portabilityType"
                                    style="margin-left: 15px;">
                                    {{ portabilityType }}
                                </mat-radio-button>
                            </mat-radio-group>
                            <mat-form-field class="flex-25" appearance="outline" *ngIf="voucherForm.value.portabilityType == 'Port In' || voucherForm.value.portabilityType == 'Port Out'">
                                <mat-label>Vendor Search</mat-label>
                                <input 
                                    type="text" 
                                    placeholder="start typing housing authority vendor name"
                                    aria-label="Vendor" 
                                    matInput 
                                    formControlName="portabilityVendor" 
                                    [matAutocomplete]="HaVendor"/>
                                <button mat-button matSuffix mat-icon-button (click)="removeVendor()">
                                    <mat-icon>close</mat-icon>
                                </button>     
                                <mat-autocomplete #HaVendor="matAutocomplete" [displayWith]="vendorSelectDisplay">
                                    <mat-option *ngFor="let ll of filteredHaVendorOptions$ | async" [value]="ll">
                                        <span>{{ll.name}}</span>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field class="flex-30" appearance="outline" *ngIf="voucherForm.value.enhancedVoucher"> 
                                <mat-label>Enhanced Minimun Rent</mat-label>
                                <input matInput formControlName="enhancedMinimumRent" type="number"/>
                            </mat-form-field>
                        </div>

                        <div class="layout-row layout-align-start-center">
                            <mat-form-field class="flex-35" appearance="outline">
                                <mat-label>Unit Search</mat-label>
                                <input 
                                    type="text" 
                                    placeholder="start typing address or ppu code of a unit with a landlord"
                                    aria-label="Unit" 
                                    matInput 
                                    (input)="refetchUnits()" 
                                    formControlName="unit" 
                                    [matAutocomplete]="auto"/>
                                <button mat-button matSuffix mat-icon-button (click)="removeUnit()">
                                    <mat-icon>close</mat-icon>
                                </button>     
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText">
                                    <mat-option *ngFor="let option of filteredUnitOptions" [value]="option" 
                                        (click)="fetchLandlord(option.node.landlordId)">
                                        <span>{{option.node.streetAddress}} Apt: {{option.node.apartmentNumber}} Unit:
                                        {{option.node.unitNumber}}</span>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field class="flex-15" appearance="outline"> 
                                <mat-label>Issued Days</mat-label>
                                <input matInput formControlName="issuedDays" type="number" (change)="updateExpiredDate()" />
                            </mat-form-field>
                            <mat-form-field class="flex-20 issued-date-picker" appearance="outline">
                                <mat-label>Date Issued</mat-label>
                                <input matInput [matDatepicker]="dateissued" formControlName="issuedOn" required
                                    (dateChange)="updateIssuedDays()" />
                                <mat-datepicker-toggle matSuffix [for]="dateissued"></mat-datepicker-toggle>
                                <mat-datepicker #dateissued></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field class="flex-20 issued-date-picker" appearance="outline">
                                <mat-label>Expiration Date</mat-label>
                                <input matInput [matDatepicker]="expirationdate" [min]="minDate" formControlName="expiresOn" required
                                    (dateChange)="updateIssuedDays()" />
                                <mat-datepicker-toggle matSuffix [for]="expirationdate"></mat-datepicker-toggle>
                                <mat-datepicker #expirationdate></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div *ngIf="voucherForm.value.unit && voucherForm.value.unit.node" class="unit-details-container">
                            <div class="layout-row layout-align-start-center">
                                <p>Selected Unit:</p>
                            </div>
                            <div class="layout-row layout-align-start-center">
                                <div class="flex-35 layout-row layout-align-start-center  unit-details">
                                    <p class="flex-30">Address -</p>
                                    <p class="flex-50">{{voucherForm.value.unit.node.streetAddress}} </p>
                                </div>
                                <div class="flex-35 layout-row layout-align-start-center  unit-details">
                                    <p class="flex-30">Apt Number -</p>
                                    <p class="flex-50">{{voucherForm.value.unit.node.apartmentNumber}}</p>
                                </div>
                            </div>
                            <div class="layout-row layout-align-start-center">
                                <div class="flex-35 layout-row layout-align-start-center  unit-details">
                                    <p class="flex-30">Landlord -</p>
                                    <p class="flex-50">{{landlordName}}</p>
                                </div>
                                <div class="flex-35 layout-row layout-align-start-center  unit-details">
                                    <p class="flex-30">Unit Number -</p>
                                    <p class="flex-50">{{voucherForm.value.unit.node.unitNumber}}</p>
                                </div>
                            </div>
                        </div>
                    </form>
                </kanso-wizard-content>

                <kanso-wizard-content *ngSwitchCase="'Form'">
                    <p *ngIf="!formIsRequired">No forms required</p>

                    <app-form-entry *ngIf="formIsRequired"
                        [formIdToDisplay]="currentFormTemplateId"
                        [recentlyCreatedFormToDisplay]="recentlyCreatedForm"  
                        [actionRequest]="actionRequest"
                        (emitDocumentId)="createdDocumentId($event)"
                        (emit58ValidationErrorsDisableNext)="checking58ValidationErrors($event)">
                    </app-form-entry>
                </kanso-wizard-content>
            </ng-container>
        </mat-step>
    </mat-horizontal-stepper>

    <div class="wizard-footer">
        <button class="hds_button"  mat-flat-button (click)="onCancelIssueVoucher()">Cancel</button>
        <button class="hds_button" [disabled]="onCheckDisableNext(stepper) || has58ValidationErrors"  [ngClass]="stepper.selectedIndex === (stepper.steps.length -1) ? 'green': ''"  mat-flat-button (click)="goToNextStep()" >{{ stepper.selectedIndex === (stepper.steps.length -1) ? 'Submit':  'Next' }}</button>
    </div>
</section>