import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from '../../kanso-common/core/service';
import {FileDetailsModel, FileroomS3UploadModel} from './file-handler.model';
import {CustomFile} from './upload-file.object';
import {Observable} from 'rxjs';
import {CoreService} from 'src/app/core/service/core.service';
@Injectable({
  providedIn: 'root',
})
export class FileHandlerService extends BaseService {
  rootUrl: string;
  defaultHeaders: any;

  constructor(public http: HttpClient, public coreService: CoreService) {
    super(http);
    this.rootUrl = sessionStorage.getItem('FILEROOM_SERVICE_URI');
    this.defaultHeaders = {
      'Content-Type': 'application/json',
      'x-api-key': sessionStorage.getItem('FILEROOM_SERVICE_API_KEY'),
      'x-site-id': sessionStorage.getItem('SITEID'),
      'x-customer-id': sessionStorage.getItem('CUSTOMERID'),
    };
  }

  async uploadS3Files(userId, household, siteSection, siteSectionId, siteSubsection, customFile: CustomFile[]): Promise<number> {
    let fileUploaded = 0;
    try {
      const commad = this.buildFileroomS3ModelCommand(userId, household, siteSection, siteSectionId, siteSubsection, customFile);
      const response: any = await this.http.post(`${this.rootUrl}/savefilesinfo`, commad, {headers: this.defaultHeaders}).toPromise();
      for (const object of response.data) {
        const fileObject = customFile.find(file => file.id == object.id);
        if (fileObject) {
          await this.uploadFileToS3(object.presignedUrl, fileObject.file).toPromise();
          fileUploaded += 1;
        }
      }
      return fileUploaded;
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  }

  uploadFileToS3(url: string, file: File): Observable<any> {
    return this.http.put<void>(url, file, {headers: {'Content-Type': file.type}});
  }

  buildFileroomS3ModelCommand(userId, household, siteSection, siteSectionId, siteSubsection, customFile: CustomFile[]) {
    const metadata: any = new Array<{key: string; value: string}>();
    if (userId) {
      metadata.push({key: 'user', value: userId});
    }
    if (siteSection) {
      const idName = `${siteSection}Id`;
      metadata.push({key: 'documentType', value: siteSection});
      metadata.push({key: idName, value: siteSectionId});
    }
    const fileDetails: FileDetailsModel[] = customFile.map(fileObject => {
      if (siteSubsection) {
        fileObject.tags.push(siteSubsection);
      }
      const fileDetail: FileDetailsModel = {
        id: fileObject.id,
        contentDisposition: `form-data; name="file"; filename="${fileObject.file.name}"`,
        contentType: fileObject.file.type,
        name: fileObject.file.name,
        length: fileObject.file.size,
        fileName: fileObject.file.name,
        description: fileObject.file.name,
        version: '1',
        tags: fileObject.tags,
      };
      return fileDetail;
    });

    const command: FileroomS3UploadModel = {
      authority: this.siteId,
      household: household,
      files: fileDetails,
      metadata: this.convertToDict(metadata),
    };

    return command;
  }

  convertToDict(metadata) {
    return metadata.reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {});
  }
}
